.border-radius{
  border-radius: $border-radius;
}
.radius{
  border-radius: $border-radius;
}
@for $i from 0 through 10 {
  .radius-#{$i}{
    border-radius: $border-radius+(.3*$i);
  }
}

.bk-obj-cover{
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 0;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;

  img{
    @extend %ObjectCover;
  }
}
.no-gutters{
  .bk-obj-md-cover{
    @include mq(md){
      left: 0;
      right: 0;
    }
  }
}
.bk-obj-md-cover{
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 80%;
  position: relative;
  z-index: 0;
  @include mq(md){
    width: auto;
    height: 100%;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
  }
  img{
    @extend %ObjectCover;
  }
}
blockquote{
  font-family: $secondary-family;
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 0;

  p{
    display: inline-block;
    margin-bottom: 0;
  }

  cite{
    font-family: $primary-family;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
  }
}

.grey-bg{
  background-color: $gray;
}

.hide-sm{
  display: block;
  @include mq(sm){
    display: none;
  }
}

.show-sm{
  display: none;
  @include mq(sm){
    display: block;
  }
}

.hide-md{
  display: block;
  @include mq(md){
    display: none;
  }
}

.show-md{
  display: none;
  @include mq(md){
    display: block;
  }
}

.hide-lg{
  display: block;
  @include mq(lg){
    display: none;
  }
}

.show-lg{
  display: none;
  @include mq(lg){
    display: block;
  }
}

.hide-xl{
  display: block;
  @include mq(xl){
    display: none;
  }
}

.show-xl{
  display: none;
  @include mq(xl){
    display: block;
  }
}
