// Spacing Margin
@for $i from 0 through 15 {
  .gutter-#{$i} {
    margin-left: -#{$i}px;
    margin-right: -#{$i}px;

    div[class*="col"]{
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }
  }
}

@include mq('sm') {
  @for $i from 0 through 15 {
    .gutter-sm-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      div[class*="col"]{
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('md') {
  @for $i from 0 through 15 {
    .gutter-md-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      div[class*="col"]{
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 15 {
    .gutter-lg-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      div[class*="col"]{
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 15 {
    .gutter-xl-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      div[class*="col"]{
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 15 {
    .gutter-xxl-#{$i} {
      margin: -#{$i}px;

      div[class*="col"]{
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}
