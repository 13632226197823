.background-primary{
  background-color: $primary-color;
}
.background-secondary{
  background-color: $primary-color;
}
.background-gray{
  background-color: $gray;
}
.background-darkgray{
  background-color: $darkgray;
}
.background-white{
  background-color: $white;
}
.background-black{
  background-color: $white;
}
.color-black{
  color: $black;
}
.color-white{
  color: $white;
}
