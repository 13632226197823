.big-text{
  font-size: 1rem;
  font-weight: 400;
  color: $primary-color;
  font-family: $secondary-family;
  text-transform: uppercase;
  @include mq(md){
    font-size: 1.3rem;
  }
}
