.bk-accordion{
  .card{
    margin-bottom: 1.2rem;
    border-radius: 0;
    border: none;
  }
  .card-header{
    padding: 0;
    border: none;
  }

  .card-body{
    border: 1px solid #e4ead6;
    border-top: 0;
    line-height: 1.8rem;
    padding: 2rem 1rem;

    @include mq(md){
      padding: 2rem;
    }
  }

  .btn-link{
      width: 100%;
      border: none;
      text-align: left;
      padding: 1rem 3rem 1rem 1rem;
      outline: 0;
      border-radius: 0;
      text-decoration: none;
      background-color: #e4ead6;
      font-family: $secondary-family;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: normal;
      @include mq(lg){
        font-size: 1.2rem;
      }
      @include mq(md){
        padding: 1rem 4rem 1rem 2rem;
      }

      &:after{
        content: "";
        position: absolute;
        background: url("../images/down.svg") no-repeat center / cover;
        width: 18px;
        height: 10px;
        right: 1.2rem;
        top: 1.5rem;
        animation: none;
        transform: rotate(180deg);
        transition: all .2s ease;
        @include mq(md){
          width: 23px;
          height: 12px;
          right: 2rem;
          top: 1.6rem;
        }
      }
  }

  .btn-link.collapsed{
    &:after{
        transform: rotate(0deg);
    }
  }
}
