.bk-plan{
  padding: 2.5rem 1.2rem;
  color: $secondary-font-color;
  position: relative;
  text-align: center;
  background: #2d825b;
  background: -moz-linear-gradient(-45deg,  #2d825b 0%, #689f38 100%);
  background: -webkit-linear-gradient(-45deg,  #2d825b 0%,#689f38 100%);
  background: linear-gradient(135deg,  #2d825b 0%,#689f38 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d825b', endColorstr='#689f38',GradientType=1 );

  @include mq(md){
    padding: 2.5rem 2rem;
  }

  &:before{
    content: "";
    background: url("../images/plan-bg.jpg") no-repeat center / cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
  }
  &.bk-2{
     &:before{
    background: url("../images/plan-bg-2.jpg") no-repeat center / cover;
    // opacity: .3;
  }
  }
   &.bk-3{
     &:before{
    background: url("../images/plan-bg-3.jpg") no-repeat center / cover;
    // opacity: .35;
  }
  }

  .inner-bk{
    position: relative;
    z-index: 2;
  }

  .title-bk{
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1rem;
    margin-bottom: 2rem;
    color: $secondary-font-color;
     @include mq(xl){
    font-size: 1.3rem;
   }
   @include mq(w3){
    font-size: 1.4rem;
   }
  }

  .price-bk{
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 3rem;
    margin-bottom: 1.5rem;
     @include mq(xl){
    font-size: 1.8rem;
   }
   @include mq(w3){
     font-size: 1.9rem;
   }

    span{
      font-size: 3rem;
      font-weight: 700;
      @include mq(xl){
    font-size: 3.5rem;
   }
   @include mq(w3){
    font-size: 4rem;
   }
    }
  }

  ul{
    margin-bottom: 2.8rem;
    padding-left: 0;
  }

  li{
    list-style: none;
    margin-bottom: 1.8rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid $white;
    text-align: left;
    font-size: 1rem;
    font-weight: 300;
     @include mq(xl){
    font-size: 1.1rem;
   }
   @include mq(w3){
    font-size: 1.2rem;
   }
  }

  .btn{
    border: none;
    background-color: $white;
    color: $primary-color;
    font-size: 1rem;
    padding: .4rem 1rem;

    &:hover{
      background-color: $primary-color;
      color: $secondary-font-color;
    }
  }
}
