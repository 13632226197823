.bk-footer {
  background-color: $primary-color;
  color: $secondary-font-color;
  font-size: 1rem;
   @include mq(xl){
    font-size: 1.1rem;
   }
    @include mq(w3){
      font-size: 1.2rem;
    }

  @include mq(msm){
    text-align: center;
  }

  ::selection {
    background-color: $secondary-color;
  }

  li {
    list-style: none;
  }

  a {
    color: $secondary-font-color;

    path{
      transition: all .2s ease;
    }

    &:hover {
      color: $secondary-color;

      path {
        fill: $secondary-color;
      }
    }
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  .title-bk {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
    color: $secondary-font-color;
    text-transform: capitalize;

    @include mq(md){
      margin-bottom: 2rem;
    }
     @include mq(xl){
    font-size: 1.25rem;
   }
    @include mq(w3){
      font-size: 1.3rem;
    }
  }

  .nav-bk {
    width: 100%;
    margin-left: inherit;
    @include mq(lg){
      margin-left: auto;
      width: max-content;
    }
    @include mq(msm){
      text-align: center;
    }

    li {
      margin-bottom: 1.1rem;
    }
  }

  .apps-bk{
    img{
      height: 3rem;
    }
  }

  .bottom-bk {
    @include mq(mlg){
      text-align: center;
    }

    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      @include mq(mlg){
        justify-content: center;
      }
    }

    li {
      padding-right: 1.4rem;

      &:last-child {
        padding-right: 0;
      }
    }

    p{
      margin-bottom: 0;
    }
  }

  .social-bk {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(lg){
      justify-content: flex-end;
    }

    a {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  p{

    img{
      height: 1.1rem;
      width: auto;
    }
  }
}
.newsletter-subscription-bk{
   margin-left: auto;
   margin-right: auto;
   max-width: 250px;
   padding-top: 5px;
      @include mq(sm){
   margin-left: 0;
   margin-right: 0;
   max-width: 100%;
      }
  form{
      border-bottom: 1px solid $white;
      .input{
          width: calc( 100% - 2rem );
          input{
        border: none;
        height: 3rem;
        background-color: transparent;
        width: 100%;

        &:focus{
          outline: none;
        }
      }
       input::placeholder{
          color:#fff;
        }
      }
      
      .btn-arrow{
        width: 2rem;
        height: 3rem;
        border: none;
        outline: none;
        position: relative;
        background-color: transparent;
        &:hover{
          svg{
            fill:$white;
          }
        }
        svg{
          position: absolute;
          top: 50%;
          left: 0;
          transform: translatey(-50%);
          width: 100%;
          height: auto;
          transition:.4s;
          fill: $white;
          z-index: 1;
        }
        input{
          border: none;
        height: 3rem;
        background-color: transparent;
        width: 100%;
        color: #fff;
        position:relative;
        z-index: 2;
        opacity:0;

        }
      }
    }
    #mc_embed_signup form #mce-responses{
       position: absolute;
    top: 100%;
    margin-top: 1rem;
    color: #000;
   
    background-color: #fff;
    left: 50%;
    transform: translatex(-50%);
    width: 100%;
  min-width: 220px;
} 
#mc_embed_signup form #mce-responses .response{
    padding: .5rem 1rem;
     border: 1px solid #13bf98;
}
#mc_embed_signup form .mce_inline_error{
    position: absolute;
    top: 100%;
    margin-top: 1rem!important;
    left: 50%;
    transform: translatex(-50%);
    width: 100%;
}
}