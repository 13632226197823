@media (min-width: 768px) {
  .container-fluid {
    padding: 0 5%;
  }
}

@media (min-width: 1681px) {
  .container,
  .container-md {
    max-width: 1300px;
  }
}

.arrow-center .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
}

.arrow-center .owl-nav .owl-prev {
  left: -6rem;
}

.arrow-center .owl-nav .owl-next {
  right: -6rem;
}

@media (max-width: 1199px) {
  .arrow-center .owl-nav .owl-prev {
    left: 0rem;
  }
  .arrow-center .owl-nav .owl-next {
    right: 0rem;
  }
}

.bk-obj-cover img, .bk-obj-md-cover img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn, .btn2, .bk-mob-tog, .bk-mob-nav .list-bk button {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

.bk-mob-nav .list-bk button::before {
  display: inline-block;
  font: normal normal normal 100%/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn, .btn2 {
  display: inline-block;
  position: relative;
  z-index: 0;
  text-align: center;
  background-color: #2d825b;
  color: #ffffff;
  border-color: #2d825b;
  border-radius: 0;
  border: solid 1px transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1rem;
  transition: .4s all ease;
}

.btn:focus, .btn2:focus, .btn:active, .btn2:active {
  box-shadow: none;
  background-color: #ffffff;
  color: #2d825b;
  border-color: #2d825b;
}

.btn:hover, .btn2:hover {
  box-shadow: none;
  background-color: #ffffff;
  color: #2d825b;
  border-color: #2d825b;
}

html {
  font-size: .9rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #646464;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.6;
}

@media (min-width: 1200px) {
  body {
    font-size: 1.2rem;
  }
}

@media (min-width: 1367px) {
  body {
    font-size: 1.3rem;
  }
}

a {
  text-decoration: none;
  outline-width: 0;
}

a:hover {
  text-decoration: none;
  outline-width: 0;
}

a:active {
  text-decoration: none;
  outline-width: 0;
}

a:visited {
  text-decoration: none;
  outline-width: 0;
}

a:focus {
  text-decoration: none;
  outline-width: 0;
}

a {
  color: #99cc33;
}

a:hover {
  color: #99cc33;
}

img {
  max-width: 100%;
  height: auto;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

hr {
  border-color: #000000;
}

sup {
  font-size: 80%;
}

fieldset label {
  font-weight: 600;
  margin-bottom: .25rem;
}

.d-flex-parent > * {
  display: flex;
}

.flex-wrap-parent > * {
  flex-wrap: wrap;
}

.bk-btn {
  margin-left: -.5rem;
}

.bk-btn .btn {
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.bk-page-nav {
  background-color: #ffffff;
  text-align: center;
}

.bk-page-nav a {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

::selection {
  color: #ffffff;
  background-color: #2d825b;
}

.spacing {
  padding: 2.5rem 0rem;
}

@media (min-width: 575px) {
  .spacing {
    padding: 3rem 0rem;
  }
}

@media (min-width: 992px) {
  .spacing {
    padding: 3.8rem 0rem;
  }
}

@media (min-width: 1200px) {
  .spacing {
    padding: 5rem 0rem;
  }
}

@media (min-width: 1681px) {
  .spacing {
    padding: 6.5rem 0rem;
  }
}

.spacing-top {
  padding-top: 2.5rem;
}

@media (min-width: 575px) {
  .spacing-top {
    padding-top: 3rem;
  }
}

@media (min-width: 992px) {
  .spacing-top {
    padding-top: 3.8rem;
  }
}

@media (min-width: 1200px) {
  .spacing-top {
    padding-top: 5rem;
  }
}

@media (min-width: 1681px) {
  .spacing-top {
    padding-top: 6.5rem;
  }
}

.spacing-bottom {
  padding-bottom: 2.5rem;
}

@media (min-width: 575px) {
  .spacing-bottom {
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .spacing-bottom {
    padding-bottom: 3.8rem;
  }
}

@media (min-width: 1200px) {
  .spacing-bottom {
    padding-bottom: 5rem;
  }
}

@media (min-width: 1681px) {
  .spacing-bottom {
    padding-bottom: 6.5rem;
  }
}

@keyframes flare {
  0% {
    width: 0;
    left: -10%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0;
    left: 110%;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 8rem;
  margin: auto;
  padding: .4rem 1.5rem;
  text-transform: capitalize;
  border: solid 1px #2d825b;
}

.btn:before {
  content: "";
  background: url("../images/logo-icon.svg") no-repeat center/contain;
  width: 2rem;
  height: 2.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: .6rem;
}

.btn path {
  transition: .4s all ease;
}

.btn:hover path {
  fill: #2d825b;
}

.btn2 {
  background-color: #ffffff;
  color: #006838;
  border-color: #006838;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 8rem;
  margin: auto;
  padding: .97rem 2.5rem;
  outline: 0;
  border-radius: 0;
}

.btn2:hover {
  background-color: #2d825b;
  color: #ffffff;
}

@media (min-width: 768px) {
  .btn2 {
    padding: .97rem 3.5rem;
  }
}

.view-more {
  padding: 0.8rem 2.5rem;
}

a {
  transition: all 0.2s ease;
}

.btn-border {
  background-color: transparent;
  color: #2d825b;
  border-color: #2d825b;
}

.btn-border:active, .btn-border:focus {
  background-color: transparent;
  color: #2d825b;
  border-color: #2d825b;
}

.btn-border:hover {
  background-color: transparent;
  color: #2d825b;
  border-color: #2d825b;
}

.btn-light {
  background-color: #ffffff;
  color: #2d825b;
  border-color: #ffffff;
}

.btn-light:active, .btn-light:focus {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.btn-light:hover {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.btn-light.btn-border {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.btn-light.btn-border:active, .btn-light.btn-border:focus {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.btn-light.btn-border:hover {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.btn-primary {
  background-color: #2d825b;
  border-color: #2d825b;
  color: #646464;
}

@keyframes right {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.btn-link {
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #2d825b;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  padding: 0.5rem 3rem 0.5rem 0;
}

.btn-link:hover {
  color: #2d825b;
  text-decoration: none;
}

.btn-link:hover::after {
  animation: right 2s infinite;
}

.btn-link::after {
  display: inline-block;
  width: 2rem;
  height: 1rem;
  margin-left: 0.5rem;
  content: '';
  vertical-align: middle;
  background-image: url("../images/icon-arrow-right.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.modal-dialog {
  margin: 2.75rem auto;
}

.modal-dialog.dialog-form {
  max-width: 650px;
}

.modal-dialog.dialog-content {
  max-width: 750px;
}

.modal-dialog .close {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  z-index: 1;
  border: solid 1px #2d825b;
  border-radius: 0;
  outline: none;
  color: #ffffff;
  opacity: 1;
  text-indent: -9999999px;
}

.modal-dialog .close::before, .modal-dialog .close::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  content: '';
  background-color: #2d825b;
  transform: rotate(45deg);
  transition: all .4s;
}

.modal-dialog .close::before {
  width: 50%;
  height: 2px;
}

.modal-dialog .close::after {
  width: 2px;
  height: 50%;
}

.modal-dialog .close:hover {
  opacity: 1;
}

.modal-dialog .close:hover::before, .modal-dialog .close:hover::after {
  opacity: 0.8;
}

.modal-dialog .modal-content {
  border: none;
  border-radius: 0;
}

.modal-dialog .modal-header {
  border-radius: 0;
  padding: 1.5rem 3rem;
  justify-content: center;
  border: none;
}

.modal-dialog .modal-title {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  color: #2d825b;
}

.modal-dialog .modal-body {
  padding: .5rem 1.5rem 2rem;
}

.modal-dialog header p {
  margin-top: -1rem;
}

.bk-pagination {
  margin-top: 2rem;
}

.bk-pagination a,
.bk-pagination span {
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  border: solid 1px #2d825b;
  border-radius: 50%;
  margin: 0 2px;
  padding: 0 .5rem;
  font-size: .9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #2d825b;
  transition: .4s;
}

.bk-pagination a:hover,
.bk-pagination span:hover {
  border-color: #2d825b;
  background-color: #2d825b;
  color: #ffffff;
}

.bk-pagination a {
  cursor: pointer;
}

.bk-pagination .current {
  border-color: #2d825b;
  background-color: #2d825b;
  color: #ffffff;
}

.bk-breadcrumb a {
  display: block;
  margin-left: .5rem;
}

.bk-breadcrumb i {
  margin-left: .5rem;
}

.h1, h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .h1, h1 {
    font-size: 2.3rem;
  }
}

@media (min-width: 768px) {
  .h1, h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1367px) {
  .h1, h1 {
    font-size: 2.8rem;
  }
}

.h1, h1, .h2, h2, .h3, h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  color: #2d825b;
  margin-bottom: 1rem;
  font-weight: 800;
}

.h2, h2 {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .h2, h2 {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .h2, h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1367px) {
  .h2, h2 {
    font-size: 2.6rem;
  }
}

.h3, h3 {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2rem;
  color: #2d825b;
}

@media (min-width: 1200px) {
  .h3, h3 {
    font-size: 1.5rem;
  }
}

.big-text {
  font-size: 1rem;
  font-weight: 400;
  color: #2d825b;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .big-text {
    font-size: 1.3rem;
  }
}

.border-radius {
  border-radius: 1.2rem;
}

.radius {
  border-radius: 1.2rem;
}

.radius-0 {
  border-radius: 1.2rem;
}

.radius-1 {
  border-radius: 1.5rem;
}

.radius-2 {
  border-radius: 1.8rem;
}

.radius-3 {
  border-radius: 2.1rem;
}

.radius-4 {
  border-radius: 2.4rem;
}

.radius-5 {
  border-radius: 2.7rem;
}

.radius-6 {
  border-radius: 3rem;
}

.radius-7 {
  border-radius: 3.3rem;
}

.radius-8 {
  border-radius: 3.6rem;
}

.radius-9 {
  border-radius: 3.9rem;
}

.radius-10 {
  border-radius: 4.2rem;
}

.bk-obj-cover {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 0;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
}

@media (min-width: 768px) {
  .no-gutters .bk-obj-md-cover {
    left: 0;
    right: 0;
  }
}

.bk-obj-md-cover {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 80%;
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {
  .bk-obj-md-cover {
    width: auto;
    height: 100%;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
  }
}

blockquote {
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 0;
}

blockquote p {
  display: inline-block;
  margin-bottom: 0;
}

blockquote cite {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
}

.grey-bg {
  background-color: #f6f6f6;
}

.hide-sm {
  display: block;
}

@media (min-width: 575px) {
  .hide-sm {
    display: none;
  }
}

.show-sm {
  display: none;
}

@media (min-width: 575px) {
  .show-sm {
    display: block;
  }
}

.hide-md {
  display: block;
}

@media (min-width: 768px) {
  .hide-md {
    display: none;
  }
}

.show-md {
  display: none;
}

@media (min-width: 768px) {
  .show-md {
    display: block;
  }
}

.hide-lg {
  display: block;
}

@media (min-width: 992px) {
  .hide-lg {
    display: none;
  }
}

.show-lg {
  display: none;
}

@media (min-width: 992px) {
  .show-lg {
    display: block;
  }
}

.hide-xl {
  display: block;
}

@media (min-width: 1200px) {
  .hide-xl {
    display: none;
  }
}

.show-xl {
  display: none;
}

@media (min-width: 1200px) {
  .show-xl {
    display: block;
  }
}

.mgt-0 {
  margin-top: 0rem;
}

.mgb-0 {
  margin-bottom: 0rem;
}

.mgl-0 {
  margin-left: 0rem;
}

.mgr-0 {
  margin-right: 0rem;
}

.mgt--0 {
  margin-top: -0rem;
}

.mgb--0 {
  margin-bottom: -0rem;
}

.mgl--0 {
  margin-left: -0rem;
}

.mgr--0 {
  margin-right: -0rem;
}

.mgt-1 {
  margin-top: 1rem;
}

.mgb-1 {
  margin-bottom: 1rem;
}

.mgl-1 {
  margin-left: 1rem;
}

.mgr-1 {
  margin-right: 1rem;
}

.mgt--1 {
  margin-top: -1rem;
}

.mgb--1 {
  margin-bottom: -1rem;
}

.mgl--1 {
  margin-left: -1rem;
}

.mgr--1 {
  margin-right: -1rem;
}

.mgt-2 {
  margin-top: 2rem;
}

.mgb-2 {
  margin-bottom: 2rem;
}

.mgl-2 {
  margin-left: 2rem;
}

.mgr-2 {
  margin-right: 2rem;
}

.mgt--2 {
  margin-top: -2rem;
}

.mgb--2 {
  margin-bottom: -2rem;
}

.mgl--2 {
  margin-left: -2rem;
}

.mgr--2 {
  margin-right: -2rem;
}

.mgt-3 {
  margin-top: 3rem;
}

.mgb-3 {
  margin-bottom: 3rem;
}

.mgl-3 {
  margin-left: 3rem;
}

.mgr-3 {
  margin-right: 3rem;
}

.mgt--3 {
  margin-top: -3rem;
}

.mgb--3 {
  margin-bottom: -3rem;
}

.mgl--3 {
  margin-left: -3rem;
}

.mgr--3 {
  margin-right: -3rem;
}

.mgt-4 {
  margin-top: 4rem;
}

.mgb-4 {
  margin-bottom: 4rem;
}

.mgl-4 {
  margin-left: 4rem;
}

.mgr-4 {
  margin-right: 4rem;
}

.mgt--4 {
  margin-top: -4rem;
}

.mgb--4 {
  margin-bottom: -4rem;
}

.mgl--4 {
  margin-left: -4rem;
}

.mgr--4 {
  margin-right: -4rem;
}

.mgt-5 {
  margin-top: 5rem;
}

.mgb-5 {
  margin-bottom: 5rem;
}

.mgl-5 {
  margin-left: 5rem;
}

.mgr-5 {
  margin-right: 5rem;
}

.mgt--5 {
  margin-top: -5rem;
}

.mgb--5 {
  margin-bottom: -5rem;
}

.mgl--5 {
  margin-left: -5rem;
}

.mgr--5 {
  margin-right: -5rem;
}

.mgt-6 {
  margin-top: 6rem;
}

.mgb-6 {
  margin-bottom: 6rem;
}

.mgl-6 {
  margin-left: 6rem;
}

.mgr-6 {
  margin-right: 6rem;
}

.mgt--6 {
  margin-top: -6rem;
}

.mgb--6 {
  margin-bottom: -6rem;
}

.mgl--6 {
  margin-left: -6rem;
}

.mgr--6 {
  margin-right: -6rem;
}

.mgt-7 {
  margin-top: 7rem;
}

.mgb-7 {
  margin-bottom: 7rem;
}

.mgl-7 {
  margin-left: 7rem;
}

.mgr-7 {
  margin-right: 7rem;
}

.mgt--7 {
  margin-top: -7rem;
}

.mgb--7 {
  margin-bottom: -7rem;
}

.mgl--7 {
  margin-left: -7rem;
}

.mgr--7 {
  margin-right: -7rem;
}

.mgt-8 {
  margin-top: 8rem;
}

.mgb-8 {
  margin-bottom: 8rem;
}

.mgl-8 {
  margin-left: 8rem;
}

.mgr-8 {
  margin-right: 8rem;
}

.mgt--8 {
  margin-top: -8rem;
}

.mgb--8 {
  margin-bottom: -8rem;
}

.mgl--8 {
  margin-left: -8rem;
}

.mgr--8 {
  margin-right: -8rem;
}

.mgt-9 {
  margin-top: 9rem;
}

.mgb-9 {
  margin-bottom: 9rem;
}

.mgl-9 {
  margin-left: 9rem;
}

.mgr-9 {
  margin-right: 9rem;
}

.mgt--9 {
  margin-top: -9rem;
}

.mgb--9 {
  margin-bottom: -9rem;
}

.mgl--9 {
  margin-left: -9rem;
}

.mgr--9 {
  margin-right: -9rem;
}

.mgt-10 {
  margin-top: 10rem;
}

.mgb-10 {
  margin-bottom: 10rem;
}

.mgl-10 {
  margin-left: 10rem;
}

.mgr-10 {
  margin-right: 10rem;
}

.mgt--10 {
  margin-top: -10rem;
}

.mgb--10 {
  margin-bottom: -10rem;
}

.mgl--10 {
  margin-left: -10rem;
}

.mgr--10 {
  margin-right: -10rem;
}

@media (min-width: 575px) {
  .mgt-sm-0 {
    margin-top: 0rem;
  }
  .mgb-sm-0 {
    margin-bottom: 0rem;
  }
  .mgl-sm-0 {
    margin-left: 0rem;
  }
  .mgr-sm-0 {
    margin-right: 0rem;
  }
  .mgt-sm--0 {
    margin-top: -0rem;
  }
  .mgb-sm--0 {
    margin-bottom: -0rem;
  }
  .mgl-sm--0 {
    margin-left: -0rem;
  }
  .mgr-sm--0 {
    margin-right: -0rem;
  }
  .mgt-sm-1 {
    margin-top: 1rem;
  }
  .mgb-sm-1 {
    margin-bottom: 1rem;
  }
  .mgl-sm-1 {
    margin-left: 1rem;
  }
  .mgr-sm-1 {
    margin-right: 1rem;
  }
  .mgt-sm--1 {
    margin-top: -1rem;
  }
  .mgb-sm--1 {
    margin-bottom: -1rem;
  }
  .mgl-sm--1 {
    margin-left: -1rem;
  }
  .mgr-sm--1 {
    margin-right: -1rem;
  }
  .mgt-sm-2 {
    margin-top: 2rem;
  }
  .mgb-sm-2 {
    margin-bottom: 2rem;
  }
  .mgl-sm-2 {
    margin-left: 2rem;
  }
  .mgr-sm-2 {
    margin-right: 2rem;
  }
  .mgt-sm--2 {
    margin-top: -2rem;
  }
  .mgb-sm--2 {
    margin-bottom: -2rem;
  }
  .mgl-sm--2 {
    margin-left: -2rem;
  }
  .mgr-sm--2 {
    margin-right: -2rem;
  }
  .mgt-sm-3 {
    margin-top: 3rem;
  }
  .mgb-sm-3 {
    margin-bottom: 3rem;
  }
  .mgl-sm-3 {
    margin-left: 3rem;
  }
  .mgr-sm-3 {
    margin-right: 3rem;
  }
  .mgt-sm--3 {
    margin-top: -3rem;
  }
  .mgb-sm--3 {
    margin-bottom: -3rem;
  }
  .mgl-sm--3 {
    margin-left: -3rem;
  }
  .mgr-sm--3 {
    margin-right: -3rem;
  }
  .mgt-sm-4 {
    margin-top: 4rem;
  }
  .mgb-sm-4 {
    margin-bottom: 4rem;
  }
  .mgl-sm-4 {
    margin-left: 4rem;
  }
  .mgr-sm-4 {
    margin-right: 4rem;
  }
  .mgt-sm--4 {
    margin-top: -4rem;
  }
  .mgb-sm--4 {
    margin-bottom: -4rem;
  }
  .mgl-sm--4 {
    margin-left: -4rem;
  }
  .mgr-sm--4 {
    margin-right: -4rem;
  }
  .mgt-sm-5 {
    margin-top: 5rem;
  }
  .mgb-sm-5 {
    margin-bottom: 5rem;
  }
  .mgl-sm-5 {
    margin-left: 5rem;
  }
  .mgr-sm-5 {
    margin-right: 5rem;
  }
  .mgt-sm--5 {
    margin-top: -5rem;
  }
  .mgb-sm--5 {
    margin-bottom: -5rem;
  }
  .mgl-sm--5 {
    margin-left: -5rem;
  }
  .mgr-sm--5 {
    margin-right: -5rem;
  }
  .mgt-sm-6 {
    margin-top: 6rem;
  }
  .mgb-sm-6 {
    margin-bottom: 6rem;
  }
  .mgl-sm-6 {
    margin-left: 6rem;
  }
  .mgr-sm-6 {
    margin-right: 6rem;
  }
  .mgt-sm--6 {
    margin-top: -6rem;
  }
  .mgb-sm--6 {
    margin-bottom: -6rem;
  }
  .mgl-sm--6 {
    margin-left: -6rem;
  }
  .mgr-sm--6 {
    margin-right: -6rem;
  }
  .mgt-sm-7 {
    margin-top: 7rem;
  }
  .mgb-sm-7 {
    margin-bottom: 7rem;
  }
  .mgl-sm-7 {
    margin-left: 7rem;
  }
  .mgr-sm-7 {
    margin-right: 7rem;
  }
  .mgt-sm--7 {
    margin-top: -7rem;
  }
  .mgb-sm--7 {
    margin-bottom: -7rem;
  }
  .mgl-sm--7 {
    margin-left: -7rem;
  }
  .mgr-sm--7 {
    margin-right: -7rem;
  }
  .mgt-sm-8 {
    margin-top: 8rem;
  }
  .mgb-sm-8 {
    margin-bottom: 8rem;
  }
  .mgl-sm-8 {
    margin-left: 8rem;
  }
  .mgr-sm-8 {
    margin-right: 8rem;
  }
  .mgt-sm--8 {
    margin-top: -8rem;
  }
  .mgb-sm--8 {
    margin-bottom: -8rem;
  }
  .mgl-sm--8 {
    margin-left: -8rem;
  }
  .mgr-sm--8 {
    margin-right: -8rem;
  }
  .mgt-sm-9 {
    margin-top: 9rem;
  }
  .mgb-sm-9 {
    margin-bottom: 9rem;
  }
  .mgl-sm-9 {
    margin-left: 9rem;
  }
  .mgr-sm-9 {
    margin-right: 9rem;
  }
  .mgt-sm--9 {
    margin-top: -9rem;
  }
  .mgb-sm--9 {
    margin-bottom: -9rem;
  }
  .mgl-sm--9 {
    margin-left: -9rem;
  }
  .mgr-sm--9 {
    margin-right: -9rem;
  }
  .mgt-sm-10 {
    margin-top: 10rem;
  }
  .mgb-sm-10 {
    margin-bottom: 10rem;
  }
  .mgl-sm-10 {
    margin-left: 10rem;
  }
  .mgr-sm-10 {
    margin-right: 10rem;
  }
  .mgt-sm--10 {
    margin-top: -10rem;
  }
  .mgb-sm--10 {
    margin-bottom: -10rem;
  }
  .mgl-sm--10 {
    margin-left: -10rem;
  }
  .mgr-sm--10 {
    margin-right: -10rem;
  }
}

@media (min-width: 768px) {
  .mgt-md-0 {
    margin-top: 0rem;
  }
  .mgb-md-0 {
    margin-bottom: 0rem;
  }
  .mgl-md-0 {
    margin-left: 0rem;
  }
  .mgr-md-0 {
    margin-right: 0rem;
  }
  .mgt-md--0 {
    margin-top: -0rem;
  }
  .mgb-md--0 {
    margin-bottom: -0rem;
  }
  .mgl-md--0 {
    margin-left: -0rem;
  }
  .mgr-md--0 {
    margin-right: -0rem;
  }
  .mgt-md-1 {
    margin-top: 1rem;
  }
  .mgb-md-1 {
    margin-bottom: 1rem;
  }
  .mgl-md-1 {
    margin-left: 1rem;
  }
  .mgr-md-1 {
    margin-right: 1rem;
  }
  .mgt-md--1 {
    margin-top: -1rem;
  }
  .mgb-md--1 {
    margin-bottom: -1rem;
  }
  .mgl-md--1 {
    margin-left: -1rem;
  }
  .mgr-md--1 {
    margin-right: -1rem;
  }
  .mgt-md-2 {
    margin-top: 2rem;
  }
  .mgb-md-2 {
    margin-bottom: 2rem;
  }
  .mgl-md-2 {
    margin-left: 2rem;
  }
  .mgr-md-2 {
    margin-right: 2rem;
  }
  .mgt-md--2 {
    margin-top: -2rem;
  }
  .mgb-md--2 {
    margin-bottom: -2rem;
  }
  .mgl-md--2 {
    margin-left: -2rem;
  }
  .mgr-md--2 {
    margin-right: -2rem;
  }
  .mgt-md-3 {
    margin-top: 3rem;
  }
  .mgb-md-3 {
    margin-bottom: 3rem;
  }
  .mgl-md-3 {
    margin-left: 3rem;
  }
  .mgr-md-3 {
    margin-right: 3rem;
  }
  .mgt-md--3 {
    margin-top: -3rem;
  }
  .mgb-md--3 {
    margin-bottom: -3rem;
  }
  .mgl-md--3 {
    margin-left: -3rem;
  }
  .mgr-md--3 {
    margin-right: -3rem;
  }
  .mgt-md-4 {
    margin-top: 4rem;
  }
  .mgb-md-4 {
    margin-bottom: 4rem;
  }
  .mgl-md-4 {
    margin-left: 4rem;
  }
  .mgr-md-4 {
    margin-right: 4rem;
  }
  .mgt-md--4 {
    margin-top: -4rem;
  }
  .mgb-md--4 {
    margin-bottom: -4rem;
  }
  .mgl-md--4 {
    margin-left: -4rem;
  }
  .mgr-md--4 {
    margin-right: -4rem;
  }
  .mgt-md-5 {
    margin-top: 5rem;
  }
  .mgb-md-5 {
    margin-bottom: 5rem;
  }
  .mgl-md-5 {
    margin-left: 5rem;
  }
  .mgr-md-5 {
    margin-right: 5rem;
  }
  .mgt-md--5 {
    margin-top: -5rem;
  }
  .mgb-md--5 {
    margin-bottom: -5rem;
  }
  .mgl-md--5 {
    margin-left: -5rem;
  }
  .mgr-md--5 {
    margin-right: -5rem;
  }
  .mgt-md-6 {
    margin-top: 6rem;
  }
  .mgb-md-6 {
    margin-bottom: 6rem;
  }
  .mgl-md-6 {
    margin-left: 6rem;
  }
  .mgr-md-6 {
    margin-right: 6rem;
  }
  .mgt-md--6 {
    margin-top: -6rem;
  }
  .mgb-md--6 {
    margin-bottom: -6rem;
  }
  .mgl-md--6 {
    margin-left: -6rem;
  }
  .mgr-md--6 {
    margin-right: -6rem;
  }
  .mgt-md-7 {
    margin-top: 7rem;
  }
  .mgb-md-7 {
    margin-bottom: 7rem;
  }
  .mgl-md-7 {
    margin-left: 7rem;
  }
  .mgr-md-7 {
    margin-right: 7rem;
  }
  .mgt-md--7 {
    margin-top: -7rem;
  }
  .mgb-md--7 {
    margin-bottom: -7rem;
  }
  .mgl-md--7 {
    margin-left: -7rem;
  }
  .mgr-md--7 {
    margin-right: -7rem;
  }
  .mgt-md-8 {
    margin-top: 8rem;
  }
  .mgb-md-8 {
    margin-bottom: 8rem;
  }
  .mgl-md-8 {
    margin-left: 8rem;
  }
  .mgr-md-8 {
    margin-right: 8rem;
  }
  .mgt-md--8 {
    margin-top: -8rem;
  }
  .mgb-md--8 {
    margin-bottom: -8rem;
  }
  .mgl-md--8 {
    margin-left: -8rem;
  }
  .mgr-md--8 {
    margin-right: -8rem;
  }
  .mgt-md-9 {
    margin-top: 9rem;
  }
  .mgb-md-9 {
    margin-bottom: 9rem;
  }
  .mgl-md-9 {
    margin-left: 9rem;
  }
  .mgr-md-9 {
    margin-right: 9rem;
  }
  .mgt-md--9 {
    margin-top: -9rem;
  }
  .mgb-md--9 {
    margin-bottom: -9rem;
  }
  .mgl-md--9 {
    margin-left: -9rem;
  }
  .mgr-md--9 {
    margin-right: -9rem;
  }
  .mgt-md-10 {
    margin-top: 10rem;
  }
  .mgb-md-10 {
    margin-bottom: 10rem;
  }
  .mgl-md-10 {
    margin-left: 10rem;
  }
  .mgr-md-10 {
    margin-right: 10rem;
  }
  .mgt-md--10 {
    margin-top: -10rem;
  }
  .mgb-md--10 {
    margin-bottom: -10rem;
  }
  .mgl-md--10 {
    margin-left: -10rem;
  }
  .mgr-md--10 {
    margin-right: -10rem;
  }
}

@media (min-width: 992px) {
  .mgt-lg-0 {
    margin-top: 0rem;
  }
  .mgb-lg-0 {
    margin-bottom: 0rem;
  }
  .mgl-lg-0 {
    margin-left: 0rem;
  }
  .mgr-lg-0 {
    margin-right: 0rem;
  }
  .mgt-lg--0 {
    margin-top: -0rem;
  }
  .mgb-lg--0 {
    margin-bottom: -0rem;
  }
  .mgl-lg--0 {
    margin-left: -0rem;
  }
  .mgr-lg--0 {
    margin-right: -0rem;
  }
  .mgt-lg-1 {
    margin-top: 1rem;
  }
  .mgb-lg-1 {
    margin-bottom: 1rem;
  }
  .mgl-lg-1 {
    margin-left: 1rem;
  }
  .mgr-lg-1 {
    margin-right: 1rem;
  }
  .mgt-lg--1 {
    margin-top: -1rem;
  }
  .mgb-lg--1 {
    margin-bottom: -1rem;
  }
  .mgl-lg--1 {
    margin-left: -1rem;
  }
  .mgr-lg--1 {
    margin-right: -1rem;
  }
  .mgt-lg-2 {
    margin-top: 2rem;
  }
  .mgb-lg-2 {
    margin-bottom: 2rem;
  }
  .mgl-lg-2 {
    margin-left: 2rem;
  }
  .mgr-lg-2 {
    margin-right: 2rem;
  }
  .mgt-lg--2 {
    margin-top: -2rem;
  }
  .mgb-lg--2 {
    margin-bottom: -2rem;
  }
  .mgl-lg--2 {
    margin-left: -2rem;
  }
  .mgr-lg--2 {
    margin-right: -2rem;
  }
  .mgt-lg-3 {
    margin-top: 3rem;
  }
  .mgb-lg-3 {
    margin-bottom: 3rem;
  }
  .mgl-lg-3 {
    margin-left: 3rem;
  }
  .mgr-lg-3 {
    margin-right: 3rem;
  }
  .mgt-lg--3 {
    margin-top: -3rem;
  }
  .mgb-lg--3 {
    margin-bottom: -3rem;
  }
  .mgl-lg--3 {
    margin-left: -3rem;
  }
  .mgr-lg--3 {
    margin-right: -3rem;
  }
  .mgt-lg-4 {
    margin-top: 4rem;
  }
  .mgb-lg-4 {
    margin-bottom: 4rem;
  }
  .mgl-lg-4 {
    margin-left: 4rem;
  }
  .mgr-lg-4 {
    margin-right: 4rem;
  }
  .mgt-lg--4 {
    margin-top: -4rem;
  }
  .mgb-lg--4 {
    margin-bottom: -4rem;
  }
  .mgl-lg--4 {
    margin-left: -4rem;
  }
  .mgr-lg--4 {
    margin-right: -4rem;
  }
  .mgt-lg-5 {
    margin-top: 5rem;
  }
  .mgb-lg-5 {
    margin-bottom: 5rem;
  }
  .mgl-lg-5 {
    margin-left: 5rem;
  }
  .mgr-lg-5 {
    margin-right: 5rem;
  }
  .mgt-lg--5 {
    margin-top: -5rem;
  }
  .mgb-lg--5 {
    margin-bottom: -5rem;
  }
  .mgl-lg--5 {
    margin-left: -5rem;
  }
  .mgr-lg--5 {
    margin-right: -5rem;
  }
  .mgt-lg-6 {
    margin-top: 6rem;
  }
  .mgb-lg-6 {
    margin-bottom: 6rem;
  }
  .mgl-lg-6 {
    margin-left: 6rem;
  }
  .mgr-lg-6 {
    margin-right: 6rem;
  }
  .mgt-lg--6 {
    margin-top: -6rem;
  }
  .mgb-lg--6 {
    margin-bottom: -6rem;
  }
  .mgl-lg--6 {
    margin-left: -6rem;
  }
  .mgr-lg--6 {
    margin-right: -6rem;
  }
  .mgt-lg-7 {
    margin-top: 7rem;
  }
  .mgb-lg-7 {
    margin-bottom: 7rem;
  }
  .mgl-lg-7 {
    margin-left: 7rem;
  }
  .mgr-lg-7 {
    margin-right: 7rem;
  }
  .mgt-lg--7 {
    margin-top: -7rem;
  }
  .mgb-lg--7 {
    margin-bottom: -7rem;
  }
  .mgl-lg--7 {
    margin-left: -7rem;
  }
  .mgr-lg--7 {
    margin-right: -7rem;
  }
  .mgt-lg-8 {
    margin-top: 8rem;
  }
  .mgb-lg-8 {
    margin-bottom: 8rem;
  }
  .mgl-lg-8 {
    margin-left: 8rem;
  }
  .mgr-lg-8 {
    margin-right: 8rem;
  }
  .mgt-lg--8 {
    margin-top: -8rem;
  }
  .mgb-lg--8 {
    margin-bottom: -8rem;
  }
  .mgl-lg--8 {
    margin-left: -8rem;
  }
  .mgr-lg--8 {
    margin-right: -8rem;
  }
  .mgt-lg-9 {
    margin-top: 9rem;
  }
  .mgb-lg-9 {
    margin-bottom: 9rem;
  }
  .mgl-lg-9 {
    margin-left: 9rem;
  }
  .mgr-lg-9 {
    margin-right: 9rem;
  }
  .mgt-lg--9 {
    margin-top: -9rem;
  }
  .mgb-lg--9 {
    margin-bottom: -9rem;
  }
  .mgl-lg--9 {
    margin-left: -9rem;
  }
  .mgr-lg--9 {
    margin-right: -9rem;
  }
  .mgt-lg-10 {
    margin-top: 10rem;
  }
  .mgb-lg-10 {
    margin-bottom: 10rem;
  }
  .mgl-lg-10 {
    margin-left: 10rem;
  }
  .mgr-lg-10 {
    margin-right: 10rem;
  }
  .mgt-lg--10 {
    margin-top: -10rem;
  }
  .mgb-lg--10 {
    margin-bottom: -10rem;
  }
  .mgl-lg--10 {
    margin-left: -10rem;
  }
  .mgr-lg--10 {
    margin-right: -10rem;
  }
}

@media (min-width: 1200px) {
  .mgt-xl-0 {
    margin-top: 0rem;
  }
  .mgb-xl-0 {
    margin-bottom: 0rem;
  }
  .mgl-xl-0 {
    margin-left: 0rem;
  }
  .mgr-xl-0 {
    margin-right: 0rem;
  }
  .mgt-xl--0 {
    margin-top: -0rem;
  }
  .mgb-xl--0 {
    margin-bottom: -0rem;
  }
  .mgl-xl--0 {
    margin-left: -0rem;
  }
  .mgr-xl--0 {
    margin-right: -0rem;
  }
  .mgt-xl-1 {
    margin-top: 1rem;
  }
  .mgb-xl-1 {
    margin-bottom: 1rem;
  }
  .mgl-xl-1 {
    margin-left: 1rem;
  }
  .mgr-xl-1 {
    margin-right: 1rem;
  }
  .mgt-xl--1 {
    margin-top: -1rem;
  }
  .mgb-xl--1 {
    margin-bottom: -1rem;
  }
  .mgl-xl--1 {
    margin-left: -1rem;
  }
  .mgr-xl--1 {
    margin-right: -1rem;
  }
  .mgt-xl-2 {
    margin-top: 2rem;
  }
  .mgb-xl-2 {
    margin-bottom: 2rem;
  }
  .mgl-xl-2 {
    margin-left: 2rem;
  }
  .mgr-xl-2 {
    margin-right: 2rem;
  }
  .mgt-xl--2 {
    margin-top: -2rem;
  }
  .mgb-xl--2 {
    margin-bottom: -2rem;
  }
  .mgl-xl--2 {
    margin-left: -2rem;
  }
  .mgr-xl--2 {
    margin-right: -2rem;
  }
  .mgt-xl-3 {
    margin-top: 3rem;
  }
  .mgb-xl-3 {
    margin-bottom: 3rem;
  }
  .mgl-xl-3 {
    margin-left: 3rem;
  }
  .mgr-xl-3 {
    margin-right: 3rem;
  }
  .mgt-xl--3 {
    margin-top: -3rem;
  }
  .mgb-xl--3 {
    margin-bottom: -3rem;
  }
  .mgl-xl--3 {
    margin-left: -3rem;
  }
  .mgr-xl--3 {
    margin-right: -3rem;
  }
  .mgt-xl-4 {
    margin-top: 4rem;
  }
  .mgb-xl-4 {
    margin-bottom: 4rem;
  }
  .mgl-xl-4 {
    margin-left: 4rem;
  }
  .mgr-xl-4 {
    margin-right: 4rem;
  }
  .mgt-xl--4 {
    margin-top: -4rem;
  }
  .mgb-xl--4 {
    margin-bottom: -4rem;
  }
  .mgl-xl--4 {
    margin-left: -4rem;
  }
  .mgr-xl--4 {
    margin-right: -4rem;
  }
  .mgt-xl-5 {
    margin-top: 5rem;
  }
  .mgb-xl-5 {
    margin-bottom: 5rem;
  }
  .mgl-xl-5 {
    margin-left: 5rem;
  }
  .mgr-xl-5 {
    margin-right: 5rem;
  }
  .mgt-xl--5 {
    margin-top: -5rem;
  }
  .mgb-xl--5 {
    margin-bottom: -5rem;
  }
  .mgl-xl--5 {
    margin-left: -5rem;
  }
  .mgr-xl--5 {
    margin-right: -5rem;
  }
  .mgt-xl-6 {
    margin-top: 6rem;
  }
  .mgb-xl-6 {
    margin-bottom: 6rem;
  }
  .mgl-xl-6 {
    margin-left: 6rem;
  }
  .mgr-xl-6 {
    margin-right: 6rem;
  }
  .mgt-xl--6 {
    margin-top: -6rem;
  }
  .mgb-xl--6 {
    margin-bottom: -6rem;
  }
  .mgl-xl--6 {
    margin-left: -6rem;
  }
  .mgr-xl--6 {
    margin-right: -6rem;
  }
  .mgt-xl-7 {
    margin-top: 7rem;
  }
  .mgb-xl-7 {
    margin-bottom: 7rem;
  }
  .mgl-xl-7 {
    margin-left: 7rem;
  }
  .mgr-xl-7 {
    margin-right: 7rem;
  }
  .mgt-xl--7 {
    margin-top: -7rem;
  }
  .mgb-xl--7 {
    margin-bottom: -7rem;
  }
  .mgl-xl--7 {
    margin-left: -7rem;
  }
  .mgr-xl--7 {
    margin-right: -7rem;
  }
  .mgt-xl-8 {
    margin-top: 8rem;
  }
  .mgb-xl-8 {
    margin-bottom: 8rem;
  }
  .mgl-xl-8 {
    margin-left: 8rem;
  }
  .mgr-xl-8 {
    margin-right: 8rem;
  }
  .mgt-xl--8 {
    margin-top: -8rem;
  }
  .mgb-xl--8 {
    margin-bottom: -8rem;
  }
  .mgl-xl--8 {
    margin-left: -8rem;
  }
  .mgr-xl--8 {
    margin-right: -8rem;
  }
  .mgt-xl-9 {
    margin-top: 9rem;
  }
  .mgb-xl-9 {
    margin-bottom: 9rem;
  }
  .mgl-xl-9 {
    margin-left: 9rem;
  }
  .mgr-xl-9 {
    margin-right: 9rem;
  }
  .mgt-xl--9 {
    margin-top: -9rem;
  }
  .mgb-xl--9 {
    margin-bottom: -9rem;
  }
  .mgl-xl--9 {
    margin-left: -9rem;
  }
  .mgr-xl--9 {
    margin-right: -9rem;
  }
  .mgt-xl-10 {
    margin-top: 10rem;
  }
  .mgb-xl-10 {
    margin-bottom: 10rem;
  }
  .mgl-xl-10 {
    margin-left: 10rem;
  }
  .mgr-xl-10 {
    margin-right: 10rem;
  }
  .mgt-xl--10 {
    margin-top: -10rem;
  }
  .mgb-xl--10 {
    margin-bottom: -10rem;
  }
  .mgl-xl--10 {
    margin-left: -10rem;
  }
  .mgr-xl--10 {
    margin-right: -10rem;
  }
}

.pdt-0 {
  padding-top: 0rem;
}

.pdb-0 {
  padding-bottom: 0rem;
}

.pdl-0 {
  padding-left: 0rem;
}

.pdr-0 {
  padding-right: 0rem;
}

.pdt--0 {
  padding-top: -0rem;
}

.pdb--0 {
  padding-bottom: -0rem;
}

.pdl--0 {
  padding-left: -0rem;
}

.pdr--0 {
  padding-right: -0rem;
}

.pd-0 {
  padding: 0rem;
}

.pdt-1 {
  padding-top: 1rem;
}

.pdb-1 {
  padding-bottom: 1rem;
}

.pdl-1 {
  padding-left: 1rem;
}

.pdr-1 {
  padding-right: 1rem;
}

.pdt--1 {
  padding-top: -1rem;
}

.pdb--1 {
  padding-bottom: -1rem;
}

.pdl--1 {
  padding-left: -1rem;
}

.pdr--1 {
  padding-right: -1rem;
}

.pd-1 {
  padding: 1rem;
}

.pdt-2 {
  padding-top: 2rem;
}

.pdb-2 {
  padding-bottom: 2rem;
}

.pdl-2 {
  padding-left: 2rem;
}

.pdr-2 {
  padding-right: 2rem;
}

.pdt--2 {
  padding-top: -2rem;
}

.pdb--2 {
  padding-bottom: -2rem;
}

.pdl--2 {
  padding-left: -2rem;
}

.pdr--2 {
  padding-right: -2rem;
}

.pd-2 {
  padding: 2rem;
}

.pdt-3 {
  padding-top: 3rem;
}

.pdb-3 {
  padding-bottom: 3rem;
}

.pdl-3 {
  padding-left: 3rem;
}

.pdr-3 {
  padding-right: 3rem;
}

.pdt--3 {
  padding-top: -3rem;
}

.pdb--3 {
  padding-bottom: -3rem;
}

.pdl--3 {
  padding-left: -3rem;
}

.pdr--3 {
  padding-right: -3rem;
}

.pd-3 {
  padding: 3rem;
}

.pdt-4 {
  padding-top: 4rem;
}

.pdb-4 {
  padding-bottom: 4rem;
}

.pdl-4 {
  padding-left: 4rem;
}

.pdr-4 {
  padding-right: 4rem;
}

.pdt--4 {
  padding-top: -4rem;
}

.pdb--4 {
  padding-bottom: -4rem;
}

.pdl--4 {
  padding-left: -4rem;
}

.pdr--4 {
  padding-right: -4rem;
}

.pd-4 {
  padding: 4rem;
}

.pdt-5 {
  padding-top: 5rem;
}

.pdb-5 {
  padding-bottom: 5rem;
}

.pdl-5 {
  padding-left: 5rem;
}

.pdr-5 {
  padding-right: 5rem;
}

.pdt--5 {
  padding-top: -5rem;
}

.pdb--5 {
  padding-bottom: -5rem;
}

.pdl--5 {
  padding-left: -5rem;
}

.pdr--5 {
  padding-right: -5rem;
}

.pd-5 {
  padding: 5rem;
}

.pdt-6 {
  padding-top: 6rem;
}

.pdb-6 {
  padding-bottom: 6rem;
}

.pdl-6 {
  padding-left: 6rem;
}

.pdr-6 {
  padding-right: 6rem;
}

.pdt--6 {
  padding-top: -6rem;
}

.pdb--6 {
  padding-bottom: -6rem;
}

.pdl--6 {
  padding-left: -6rem;
}

.pdr--6 {
  padding-right: -6rem;
}

.pd-6 {
  padding: 6rem;
}

.pdt-7 {
  padding-top: 7rem;
}

.pdb-7 {
  padding-bottom: 7rem;
}

.pdl-7 {
  padding-left: 7rem;
}

.pdr-7 {
  padding-right: 7rem;
}

.pdt--7 {
  padding-top: -7rem;
}

.pdb--7 {
  padding-bottom: -7rem;
}

.pdl--7 {
  padding-left: -7rem;
}

.pdr--7 {
  padding-right: -7rem;
}

.pd-7 {
  padding: 7rem;
}

.pdt-8 {
  padding-top: 8rem;
}

.pdb-8 {
  padding-bottom: 8rem;
}

.pdl-8 {
  padding-left: 8rem;
}

.pdr-8 {
  padding-right: 8rem;
}

.pdt--8 {
  padding-top: -8rem;
}

.pdb--8 {
  padding-bottom: -8rem;
}

.pdl--8 {
  padding-left: -8rem;
}

.pdr--8 {
  padding-right: -8rem;
}

.pd-8 {
  padding: 8rem;
}

.pdt-9 {
  padding-top: 9rem;
}

.pdb-9 {
  padding-bottom: 9rem;
}

.pdl-9 {
  padding-left: 9rem;
}

.pdr-9 {
  padding-right: 9rem;
}

.pdt--9 {
  padding-top: -9rem;
}

.pdb--9 {
  padding-bottom: -9rem;
}

.pdl--9 {
  padding-left: -9rem;
}

.pdr--9 {
  padding-right: -9rem;
}

.pd-9 {
  padding: 9rem;
}

.pdt-10 {
  padding-top: 10rem;
}

.pdb-10 {
  padding-bottom: 10rem;
}

.pdl-10 {
  padding-left: 10rem;
}

.pdr-10 {
  padding-right: 10rem;
}

.pdt--10 {
  padding-top: -10rem;
}

.pdb--10 {
  padding-bottom: -10rem;
}

.pdl--10 {
  padding-left: -10rem;
}

.pdr--10 {
  padding-right: -10rem;
}

.pd-10 {
  padding: 10rem;
}

@media (min-width: 768px) {
  .pdt-md-0 {
    padding-top: 0rem;
  }
  .pdb-md-0 {
    padding-bottom: 0rem;
  }
  .pdl-md-0 {
    padding-left: 0rem;
  }
  .pdr-md-0 {
    padding-right: 0rem;
  }
  .pdt-md--0 {
    padding-top: -0rem;
  }
  .pdb-md--0 {
    padding-bottom: -0rem;
  }
  .pdl-md--0 {
    padding-left: -0rem;
  }
  .pdr-md--0 {
    padding-right: -0rem;
  }
  .pd-md-0 {
    padding: 0rem;
  }
  .pdt-md-1 {
    padding-top: 1rem;
  }
  .pdb-md-1 {
    padding-bottom: 1rem;
  }
  .pdl-md-1 {
    padding-left: 1rem;
  }
  .pdr-md-1 {
    padding-right: 1rem;
  }
  .pdt-md--1 {
    padding-top: -1rem;
  }
  .pdb-md--1 {
    padding-bottom: -1rem;
  }
  .pdl-md--1 {
    padding-left: -1rem;
  }
  .pdr-md--1 {
    padding-right: -1rem;
  }
  .pd-md-1 {
    padding: 1rem;
  }
  .pdt-md-2 {
    padding-top: 2rem;
  }
  .pdb-md-2 {
    padding-bottom: 2rem;
  }
  .pdl-md-2 {
    padding-left: 2rem;
  }
  .pdr-md-2 {
    padding-right: 2rem;
  }
  .pdt-md--2 {
    padding-top: -2rem;
  }
  .pdb-md--2 {
    padding-bottom: -2rem;
  }
  .pdl-md--2 {
    padding-left: -2rem;
  }
  .pdr-md--2 {
    padding-right: -2rem;
  }
  .pd-md-2 {
    padding: 2rem;
  }
  .pdt-md-3 {
    padding-top: 3rem;
  }
  .pdb-md-3 {
    padding-bottom: 3rem;
  }
  .pdl-md-3 {
    padding-left: 3rem;
  }
  .pdr-md-3 {
    padding-right: 3rem;
  }
  .pdt-md--3 {
    padding-top: -3rem;
  }
  .pdb-md--3 {
    padding-bottom: -3rem;
  }
  .pdl-md--3 {
    padding-left: -3rem;
  }
  .pdr-md--3 {
    padding-right: -3rem;
  }
  .pd-md-3 {
    padding: 3rem;
  }
  .pdt-md-4 {
    padding-top: 4rem;
  }
  .pdb-md-4 {
    padding-bottom: 4rem;
  }
  .pdl-md-4 {
    padding-left: 4rem;
  }
  .pdr-md-4 {
    padding-right: 4rem;
  }
  .pdt-md--4 {
    padding-top: -4rem;
  }
  .pdb-md--4 {
    padding-bottom: -4rem;
  }
  .pdl-md--4 {
    padding-left: -4rem;
  }
  .pdr-md--4 {
    padding-right: -4rem;
  }
  .pd-md-4 {
    padding: 4rem;
  }
  .pdt-md-5 {
    padding-top: 5rem;
  }
  .pdb-md-5 {
    padding-bottom: 5rem;
  }
  .pdl-md-5 {
    padding-left: 5rem;
  }
  .pdr-md-5 {
    padding-right: 5rem;
  }
  .pdt-md--5 {
    padding-top: -5rem;
  }
  .pdb-md--5 {
    padding-bottom: -5rem;
  }
  .pdl-md--5 {
    padding-left: -5rem;
  }
  .pdr-md--5 {
    padding-right: -5rem;
  }
  .pd-md-5 {
    padding: 5rem;
  }
  .pdt-md-6 {
    padding-top: 6rem;
  }
  .pdb-md-6 {
    padding-bottom: 6rem;
  }
  .pdl-md-6 {
    padding-left: 6rem;
  }
  .pdr-md-6 {
    padding-right: 6rem;
  }
  .pdt-md--6 {
    padding-top: -6rem;
  }
  .pdb-md--6 {
    padding-bottom: -6rem;
  }
  .pdl-md--6 {
    padding-left: -6rem;
  }
  .pdr-md--6 {
    padding-right: -6rem;
  }
  .pd-md-6 {
    padding: 6rem;
  }
  .pdt-md-7 {
    padding-top: 7rem;
  }
  .pdb-md-7 {
    padding-bottom: 7rem;
  }
  .pdl-md-7 {
    padding-left: 7rem;
  }
  .pdr-md-7 {
    padding-right: 7rem;
  }
  .pdt-md--7 {
    padding-top: -7rem;
  }
  .pdb-md--7 {
    padding-bottom: -7rem;
  }
  .pdl-md--7 {
    padding-left: -7rem;
  }
  .pdr-md--7 {
    padding-right: -7rem;
  }
  .pd-md-7 {
    padding: 7rem;
  }
  .pdt-md-8 {
    padding-top: 8rem;
  }
  .pdb-md-8 {
    padding-bottom: 8rem;
  }
  .pdl-md-8 {
    padding-left: 8rem;
  }
  .pdr-md-8 {
    padding-right: 8rem;
  }
  .pdt-md--8 {
    padding-top: -8rem;
  }
  .pdb-md--8 {
    padding-bottom: -8rem;
  }
  .pdl-md--8 {
    padding-left: -8rem;
  }
  .pdr-md--8 {
    padding-right: -8rem;
  }
  .pd-md-8 {
    padding: 8rem;
  }
  .pdt-md-9 {
    padding-top: 9rem;
  }
  .pdb-md-9 {
    padding-bottom: 9rem;
  }
  .pdl-md-9 {
    padding-left: 9rem;
  }
  .pdr-md-9 {
    padding-right: 9rem;
  }
  .pdt-md--9 {
    padding-top: -9rem;
  }
  .pdb-md--9 {
    padding-bottom: -9rem;
  }
  .pdl-md--9 {
    padding-left: -9rem;
  }
  .pdr-md--9 {
    padding-right: -9rem;
  }
  .pd-md-9 {
    padding: 9rem;
  }
  .pdt-md-10 {
    padding-top: 10rem;
  }
  .pdb-md-10 {
    padding-bottom: 10rem;
  }
  .pdl-md-10 {
    padding-left: 10rem;
  }
  .pdr-md-10 {
    padding-right: 10rem;
  }
  .pdt-md--10 {
    padding-top: -10rem;
  }
  .pdb-md--10 {
    padding-bottom: -10rem;
  }
  .pdl-md--10 {
    padding-left: -10rem;
  }
  .pdr-md--10 {
    padding-right: -10rem;
  }
  .pd-md-10 {
    padding: 10rem;
  }
}

@media (min-width: 992px) {
  .pdt-lg-0 {
    padding-top: 0rem;
  }
  .pdb-lg-0 {
    padding-bottom: 0rem;
  }
  .pdl-lg-0 {
    padding-left: 0rem;
  }
  .pdr-lg-0 {
    padding-right: 0rem;
  }
  .pdt-lg--0 {
    padding-top: -0rem;
  }
  .pdb-lg--0 {
    padding-bottom: -0rem;
  }
  .pdl-lg--0 {
    padding-left: -0rem;
  }
  .pdr-lg--0 {
    padding-right: -0rem;
  }
  .pd-lg-0 {
    padding: 0rem;
  }
  .pdt-lg-1 {
    padding-top: 1rem;
  }
  .pdb-lg-1 {
    padding-bottom: 1rem;
  }
  .pdl-lg-1 {
    padding-left: 1rem;
  }
  .pdr-lg-1 {
    padding-right: 1rem;
  }
  .pdt-lg--1 {
    padding-top: -1rem;
  }
  .pdb-lg--1 {
    padding-bottom: -1rem;
  }
  .pdl-lg--1 {
    padding-left: -1rem;
  }
  .pdr-lg--1 {
    padding-right: -1rem;
  }
  .pd-lg-1 {
    padding: 1rem;
  }
  .pdt-lg-2 {
    padding-top: 2rem;
  }
  .pdb-lg-2 {
    padding-bottom: 2rem;
  }
  .pdl-lg-2 {
    padding-left: 2rem;
  }
  .pdr-lg-2 {
    padding-right: 2rem;
  }
  .pdt-lg--2 {
    padding-top: -2rem;
  }
  .pdb-lg--2 {
    padding-bottom: -2rem;
  }
  .pdl-lg--2 {
    padding-left: -2rem;
  }
  .pdr-lg--2 {
    padding-right: -2rem;
  }
  .pd-lg-2 {
    padding: 2rem;
  }
  .pdt-lg-3 {
    padding-top: 3rem;
  }
  .pdb-lg-3 {
    padding-bottom: 3rem;
  }
  .pdl-lg-3 {
    padding-left: 3rem;
  }
  .pdr-lg-3 {
    padding-right: 3rem;
  }
  .pdt-lg--3 {
    padding-top: -3rem;
  }
  .pdb-lg--3 {
    padding-bottom: -3rem;
  }
  .pdl-lg--3 {
    padding-left: -3rem;
  }
  .pdr-lg--3 {
    padding-right: -3rem;
  }
  .pd-lg-3 {
    padding: 3rem;
  }
  .pdt-lg-4 {
    padding-top: 4rem;
  }
  .pdb-lg-4 {
    padding-bottom: 4rem;
  }
  .pdl-lg-4 {
    padding-left: 4rem;
  }
  .pdr-lg-4 {
    padding-right: 4rem;
  }
  .pdt-lg--4 {
    padding-top: -4rem;
  }
  .pdb-lg--4 {
    padding-bottom: -4rem;
  }
  .pdl-lg--4 {
    padding-left: -4rem;
  }
  .pdr-lg--4 {
    padding-right: -4rem;
  }
  .pd-lg-4 {
    padding: 4rem;
  }
  .pdt-lg-5 {
    padding-top: 5rem;
  }
  .pdb-lg-5 {
    padding-bottom: 5rem;
  }
  .pdl-lg-5 {
    padding-left: 5rem;
  }
  .pdr-lg-5 {
    padding-right: 5rem;
  }
  .pdt-lg--5 {
    padding-top: -5rem;
  }
  .pdb-lg--5 {
    padding-bottom: -5rem;
  }
  .pdl-lg--5 {
    padding-left: -5rem;
  }
  .pdr-lg--5 {
    padding-right: -5rem;
  }
  .pd-lg-5 {
    padding: 5rem;
  }
  .pdt-lg-6 {
    padding-top: 6rem;
  }
  .pdb-lg-6 {
    padding-bottom: 6rem;
  }
  .pdl-lg-6 {
    padding-left: 6rem;
  }
  .pdr-lg-6 {
    padding-right: 6rem;
  }
  .pdt-lg--6 {
    padding-top: -6rem;
  }
  .pdb-lg--6 {
    padding-bottom: -6rem;
  }
  .pdl-lg--6 {
    padding-left: -6rem;
  }
  .pdr-lg--6 {
    padding-right: -6rem;
  }
  .pd-lg-6 {
    padding: 6rem;
  }
  .pdt-lg-7 {
    padding-top: 7rem;
  }
  .pdb-lg-7 {
    padding-bottom: 7rem;
  }
  .pdl-lg-7 {
    padding-left: 7rem;
  }
  .pdr-lg-7 {
    padding-right: 7rem;
  }
  .pdt-lg--7 {
    padding-top: -7rem;
  }
  .pdb-lg--7 {
    padding-bottom: -7rem;
  }
  .pdl-lg--7 {
    padding-left: -7rem;
  }
  .pdr-lg--7 {
    padding-right: -7rem;
  }
  .pd-lg-7 {
    padding: 7rem;
  }
  .pdt-lg-8 {
    padding-top: 8rem;
  }
  .pdb-lg-8 {
    padding-bottom: 8rem;
  }
  .pdl-lg-8 {
    padding-left: 8rem;
  }
  .pdr-lg-8 {
    padding-right: 8rem;
  }
  .pdt-lg--8 {
    padding-top: -8rem;
  }
  .pdb-lg--8 {
    padding-bottom: -8rem;
  }
  .pdl-lg--8 {
    padding-left: -8rem;
  }
  .pdr-lg--8 {
    padding-right: -8rem;
  }
  .pd-lg-8 {
    padding: 8rem;
  }
  .pdt-lg-9 {
    padding-top: 9rem;
  }
  .pdb-lg-9 {
    padding-bottom: 9rem;
  }
  .pdl-lg-9 {
    padding-left: 9rem;
  }
  .pdr-lg-9 {
    padding-right: 9rem;
  }
  .pdt-lg--9 {
    padding-top: -9rem;
  }
  .pdb-lg--9 {
    padding-bottom: -9rem;
  }
  .pdl-lg--9 {
    padding-left: -9rem;
  }
  .pdr-lg--9 {
    padding-right: -9rem;
  }
  .pd-lg-9 {
    padding: 9rem;
  }
  .pdt-lg-10 {
    padding-top: 10rem;
  }
  .pdb-lg-10 {
    padding-bottom: 10rem;
  }
  .pdl-lg-10 {
    padding-left: 10rem;
  }
  .pdr-lg-10 {
    padding-right: 10rem;
  }
  .pdt-lg--10 {
    padding-top: -10rem;
  }
  .pdb-lg--10 {
    padding-bottom: -10rem;
  }
  .pdl-lg--10 {
    padding-left: -10rem;
  }
  .pdr-lg--10 {
    padding-right: -10rem;
  }
  .pd-lg-10 {
    padding: 10rem;
  }
}

@media (min-width: 1200px) {
  .pdt-xl-0 {
    padding-top: 0rem;
  }
  .pdb-xl-0 {
    padding-bottom: 0rem;
  }
  .pdl-xl-0 {
    padding-left: 0rem;
  }
  .pdr-xl-0 {
    padding-right: 0rem;
  }
  .pdt-xl--0 {
    padding-top: -0rem;
  }
  .pdb-xl--0 {
    padding-bottom: -0rem;
  }
  .pdl-xl--0 {
    padding-left: -0rem;
  }
  .pdr-xl--0 {
    padding-right: -0rem;
  }
  .pd-xl-0 {
    padding: 0rem;
  }
  .pdt-xl-1 {
    padding-top: 1rem;
  }
  .pdb-xl-1 {
    padding-bottom: 1rem;
  }
  .pdl-xl-1 {
    padding-left: 1rem;
  }
  .pdr-xl-1 {
    padding-right: 1rem;
  }
  .pdt-xl--1 {
    padding-top: -1rem;
  }
  .pdb-xl--1 {
    padding-bottom: -1rem;
  }
  .pdl-xl--1 {
    padding-left: -1rem;
  }
  .pdr-xl--1 {
    padding-right: -1rem;
  }
  .pd-xl-1 {
    padding: 1rem;
  }
  .pdt-xl-2 {
    padding-top: 2rem;
  }
  .pdb-xl-2 {
    padding-bottom: 2rem;
  }
  .pdl-xl-2 {
    padding-left: 2rem;
  }
  .pdr-xl-2 {
    padding-right: 2rem;
  }
  .pdt-xl--2 {
    padding-top: -2rem;
  }
  .pdb-xl--2 {
    padding-bottom: -2rem;
  }
  .pdl-xl--2 {
    padding-left: -2rem;
  }
  .pdr-xl--2 {
    padding-right: -2rem;
  }
  .pd-xl-2 {
    padding: 2rem;
  }
  .pdt-xl-3 {
    padding-top: 3rem;
  }
  .pdb-xl-3 {
    padding-bottom: 3rem;
  }
  .pdl-xl-3 {
    padding-left: 3rem;
  }
  .pdr-xl-3 {
    padding-right: 3rem;
  }
  .pdt-xl--3 {
    padding-top: -3rem;
  }
  .pdb-xl--3 {
    padding-bottom: -3rem;
  }
  .pdl-xl--3 {
    padding-left: -3rem;
  }
  .pdr-xl--3 {
    padding-right: -3rem;
  }
  .pd-xl-3 {
    padding: 3rem;
  }
  .pdt-xl-4 {
    padding-top: 4rem;
  }
  .pdb-xl-4 {
    padding-bottom: 4rem;
  }
  .pdl-xl-4 {
    padding-left: 4rem;
  }
  .pdr-xl-4 {
    padding-right: 4rem;
  }
  .pdt-xl--4 {
    padding-top: -4rem;
  }
  .pdb-xl--4 {
    padding-bottom: -4rem;
  }
  .pdl-xl--4 {
    padding-left: -4rem;
  }
  .pdr-xl--4 {
    padding-right: -4rem;
  }
  .pd-xl-4 {
    padding: 4rem;
  }
  .pdt-xl-5 {
    padding-top: 5rem;
  }
  .pdb-xl-5 {
    padding-bottom: 5rem;
  }
  .pdl-xl-5 {
    padding-left: 5rem;
  }
  .pdr-xl-5 {
    padding-right: 5rem;
  }
  .pdt-xl--5 {
    padding-top: -5rem;
  }
  .pdb-xl--5 {
    padding-bottom: -5rem;
  }
  .pdl-xl--5 {
    padding-left: -5rem;
  }
  .pdr-xl--5 {
    padding-right: -5rem;
  }
  .pd-xl-5 {
    padding: 5rem;
  }
  .pdt-xl-6 {
    padding-top: 6rem;
  }
  .pdb-xl-6 {
    padding-bottom: 6rem;
  }
  .pdl-xl-6 {
    padding-left: 6rem;
  }
  .pdr-xl-6 {
    padding-right: 6rem;
  }
  .pdt-xl--6 {
    padding-top: -6rem;
  }
  .pdb-xl--6 {
    padding-bottom: -6rem;
  }
  .pdl-xl--6 {
    padding-left: -6rem;
  }
  .pdr-xl--6 {
    padding-right: -6rem;
  }
  .pd-xl-6 {
    padding: 6rem;
  }
  .pdt-xl-7 {
    padding-top: 7rem;
  }
  .pdb-xl-7 {
    padding-bottom: 7rem;
  }
  .pdl-xl-7 {
    padding-left: 7rem;
  }
  .pdr-xl-7 {
    padding-right: 7rem;
  }
  .pdt-xl--7 {
    padding-top: -7rem;
  }
  .pdb-xl--7 {
    padding-bottom: -7rem;
  }
  .pdl-xl--7 {
    padding-left: -7rem;
  }
  .pdr-xl--7 {
    padding-right: -7rem;
  }
  .pd-xl-7 {
    padding: 7rem;
  }
  .pdt-xl-8 {
    padding-top: 8rem;
  }
  .pdb-xl-8 {
    padding-bottom: 8rem;
  }
  .pdl-xl-8 {
    padding-left: 8rem;
  }
  .pdr-xl-8 {
    padding-right: 8rem;
  }
  .pdt-xl--8 {
    padding-top: -8rem;
  }
  .pdb-xl--8 {
    padding-bottom: -8rem;
  }
  .pdl-xl--8 {
    padding-left: -8rem;
  }
  .pdr-xl--8 {
    padding-right: -8rem;
  }
  .pd-xl-8 {
    padding: 8rem;
  }
  .pdt-xl-9 {
    padding-top: 9rem;
  }
  .pdb-xl-9 {
    padding-bottom: 9rem;
  }
  .pdl-xl-9 {
    padding-left: 9rem;
  }
  .pdr-xl-9 {
    padding-right: 9rem;
  }
  .pdt-xl--9 {
    padding-top: -9rem;
  }
  .pdb-xl--9 {
    padding-bottom: -9rem;
  }
  .pdl-xl--9 {
    padding-left: -9rem;
  }
  .pdr-xl--9 {
    padding-right: -9rem;
  }
  .pd-xl-9 {
    padding: 9rem;
  }
  .pdt-xl-10 {
    padding-top: 10rem;
  }
  .pdb-xl-10 {
    padding-bottom: 10rem;
  }
  .pdl-xl-10 {
    padding-left: 10rem;
  }
  .pdr-xl-10 {
    padding-right: 10rem;
  }
  .pdt-xl--10 {
    padding-top: -10rem;
  }
  .pdb-xl--10 {
    padding-bottom: -10rem;
  }
  .pdl-xl--10 {
    padding-left: -10rem;
  }
  .pdr-xl--10 {
    padding-right: -10rem;
  }
  .pd-xl-10 {
    padding: 10rem;
  }
}

.size-0 {
  font-size: 1.1rem;
}

.size-1 {
  font-size: 1.2rem;
}

.size-2 {
  font-size: 1.3rem;
}

.size-3 {
  font-size: 1.4rem;
}

.size-4 {
  font-size: 1.5rem;
}

.size-5 {
  font-size: 1.6rem;
}

.size-6 {
  font-size: 1.7rem;
}

.size-7 {
  font-size: 1.8rem;
}

.size-8 {
  font-size: 1.9rem;
}

.size-9 {
  font-size: 2rem;
}

.size-10 {
  font-size: 2.1rem;
}

@media (min-width: 768px) {
  .size-md-0 {
    font-size: 1.1rem;
  }
  .size-md-1 {
    font-size: 1.2rem;
  }
  .size-md-2 {
    font-size: 1.3rem;
  }
  .size-md-3 {
    font-size: 1.4rem;
  }
  .size-md-4 {
    font-size: 1.5rem;
  }
  .size-md-5 {
    font-size: 1.6rem;
  }
  .size-md-6 {
    font-size: 1.7rem;
  }
  .size-md-7 {
    font-size: 1.8rem;
  }
  .size-md-8 {
    font-size: 1.9rem;
  }
  .size-md-9 {
    font-size: 2rem;
  }
  .size-md-10 {
    font-size: 2.1rem;
  }
}

@media (min-width: 992px) {
  .size-lg-0 {
    font-size: 1.1rem;
  }
  .size-lg-1 {
    font-size: 1.2rem;
  }
  .size-lg-2 {
    font-size: 1.3rem;
  }
  .size-lg-3 {
    font-size: 1.4rem;
  }
  .size-lg-4 {
    font-size: 1.5rem;
  }
  .size-lg-5 {
    font-size: 1.6rem;
  }
  .size-lg-6 {
    font-size: 1.7rem;
  }
  .size-lg-7 {
    font-size: 1.8rem;
  }
  .size-lg-8 {
    font-size: 1.9rem;
  }
  .size-lg-9 {
    font-size: 2rem;
  }
  .size-lg-10 {
    font-size: 2.1rem;
  }
}

@media (min-width: 1200px) {
  .size-xl-0 {
    font-size: 1.1rem;
  }
  .size-xl-1 {
    font-size: 1.2rem;
  }
  .size-xl-2 {
    font-size: 1.3rem;
  }
  .size-xl-3 {
    font-size: 1.4rem;
  }
  .size-xl-4 {
    font-size: 1.5rem;
  }
  .size-xl-5 {
    font-size: 1.6rem;
  }
  .size-xl-6 {
    font-size: 1.7rem;
  }
  .size-xl-7 {
    font-size: 1.8rem;
  }
  .size-xl-8 {
    font-size: 1.9rem;
  }
  .size-xl-9 {
    font-size: 2rem;
  }
  .size-xl-10 {
    font-size: 2.1rem;
  }
}

.gutter-0 {
  margin-left: -0px;
  margin-right: -0px;
}

.gutter-0 div[class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.gutter-1 {
  margin-left: -1px;
  margin-right: -1px;
}

.gutter-1 div[class*="col"] {
  padding-left: 1px;
  padding-right: 1px;
}

.gutter-2 {
  margin-left: -2px;
  margin-right: -2px;
}

.gutter-2 div[class*="col"] {
  padding-left: 2px;
  padding-right: 2px;
}

.gutter-3 {
  margin-left: -3px;
  margin-right: -3px;
}

.gutter-3 div[class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}

.gutter-4 {
  margin-left: -4px;
  margin-right: -4px;
}

.gutter-4 div[class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.gutter-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.gutter-5 div[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.gutter-6 {
  margin-left: -6px;
  margin-right: -6px;
}

.gutter-6 div[class*="col"] {
  padding-left: 6px;
  padding-right: 6px;
}

.gutter-7 {
  margin-left: -7px;
  margin-right: -7px;
}

.gutter-7 div[class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.gutter-8 {
  margin-left: -8px;
  margin-right: -8px;
}

.gutter-8 div[class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.gutter-9 {
  margin-left: -9px;
  margin-right: -9px;
}

.gutter-9 div[class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

.gutter-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.gutter-10 div[class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.gutter-11 {
  margin-left: -11px;
  margin-right: -11px;
}

.gutter-11 div[class*="col"] {
  padding-left: 11px;
  padding-right: 11px;
}

.gutter-12 {
  margin-left: -12px;
  margin-right: -12px;
}

.gutter-12 div[class*="col"] {
  padding-left: 12px;
  padding-right: 12px;
}

.gutter-13 {
  margin-left: -13px;
  margin-right: -13px;
}

.gutter-13 div[class*="col"] {
  padding-left: 13px;
  padding-right: 13px;
}

.gutter-14 {
  margin-left: -14px;
  margin-right: -14px;
}

.gutter-14 div[class*="col"] {
  padding-left: 14px;
  padding-right: 14px;
}

.gutter-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.gutter-15 div[class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 575px) {
  .gutter-sm-0 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .gutter-sm-0 div[class*="col"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  .gutter-sm-1 {
    margin-left: -1px;
    margin-right: -1px;
  }
  .gutter-sm-1 div[class*="col"] {
    padding-left: 1px;
    padding-right: 1px;
  }
  .gutter-sm-2 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .gutter-sm-2 div[class*="col"] {
    padding-left: 2px;
    padding-right: 2px;
  }
  .gutter-sm-3 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .gutter-sm-3 div[class*="col"] {
    padding-left: 3px;
    padding-right: 3px;
  }
  .gutter-sm-4 {
    margin-left: -4px;
    margin-right: -4px;
  }
  .gutter-sm-4 div[class*="col"] {
    padding-left: 4px;
    padding-right: 4px;
  }
  .gutter-sm-5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .gutter-sm-5 div[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .gutter-sm-6 {
    margin-left: -6px;
    margin-right: -6px;
  }
  .gutter-sm-6 div[class*="col"] {
    padding-left: 6px;
    padding-right: 6px;
  }
  .gutter-sm-7 {
    margin-left: -7px;
    margin-right: -7px;
  }
  .gutter-sm-7 div[class*="col"] {
    padding-left: 7px;
    padding-right: 7px;
  }
  .gutter-sm-8 {
    margin-left: -8px;
    margin-right: -8px;
  }
  .gutter-sm-8 div[class*="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .gutter-sm-9 {
    margin-left: -9px;
    margin-right: -9px;
  }
  .gutter-sm-9 div[class*="col"] {
    padding-left: 9px;
    padding-right: 9px;
  }
  .gutter-sm-10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .gutter-sm-10 div[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gutter-sm-11 {
    margin-left: -11px;
    margin-right: -11px;
  }
  .gutter-sm-11 div[class*="col"] {
    padding-left: 11px;
    padding-right: 11px;
  }
  .gutter-sm-12 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .gutter-sm-12 div[class*="col"] {
    padding-left: 12px;
    padding-right: 12px;
  }
  .gutter-sm-13 {
    margin-left: -13px;
    margin-right: -13px;
  }
  .gutter-sm-13 div[class*="col"] {
    padding-left: 13px;
    padding-right: 13px;
  }
  .gutter-sm-14 {
    margin-left: -14px;
    margin-right: -14px;
  }
  .gutter-sm-14 div[class*="col"] {
    padding-left: 14px;
    padding-right: 14px;
  }
  .gutter-sm-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .gutter-sm-15 div[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .gutter-md-0 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .gutter-md-0 div[class*="col"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  .gutter-md-1 {
    margin-left: -1px;
    margin-right: -1px;
  }
  .gutter-md-1 div[class*="col"] {
    padding-left: 1px;
    padding-right: 1px;
  }
  .gutter-md-2 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .gutter-md-2 div[class*="col"] {
    padding-left: 2px;
    padding-right: 2px;
  }
  .gutter-md-3 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .gutter-md-3 div[class*="col"] {
    padding-left: 3px;
    padding-right: 3px;
  }
  .gutter-md-4 {
    margin-left: -4px;
    margin-right: -4px;
  }
  .gutter-md-4 div[class*="col"] {
    padding-left: 4px;
    padding-right: 4px;
  }
  .gutter-md-5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .gutter-md-5 div[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .gutter-md-6 {
    margin-left: -6px;
    margin-right: -6px;
  }
  .gutter-md-6 div[class*="col"] {
    padding-left: 6px;
    padding-right: 6px;
  }
  .gutter-md-7 {
    margin-left: -7px;
    margin-right: -7px;
  }
  .gutter-md-7 div[class*="col"] {
    padding-left: 7px;
    padding-right: 7px;
  }
  .gutter-md-8 {
    margin-left: -8px;
    margin-right: -8px;
  }
  .gutter-md-8 div[class*="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .gutter-md-9 {
    margin-left: -9px;
    margin-right: -9px;
  }
  .gutter-md-9 div[class*="col"] {
    padding-left: 9px;
    padding-right: 9px;
  }
  .gutter-md-10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .gutter-md-10 div[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gutter-md-11 {
    margin-left: -11px;
    margin-right: -11px;
  }
  .gutter-md-11 div[class*="col"] {
    padding-left: 11px;
    padding-right: 11px;
  }
  .gutter-md-12 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .gutter-md-12 div[class*="col"] {
    padding-left: 12px;
    padding-right: 12px;
  }
  .gutter-md-13 {
    margin-left: -13px;
    margin-right: -13px;
  }
  .gutter-md-13 div[class*="col"] {
    padding-left: 13px;
    padding-right: 13px;
  }
  .gutter-md-14 {
    margin-left: -14px;
    margin-right: -14px;
  }
  .gutter-md-14 div[class*="col"] {
    padding-left: 14px;
    padding-right: 14px;
  }
  .gutter-md-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .gutter-md-15 div[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .gutter-lg-0 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .gutter-lg-0 div[class*="col"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  .gutter-lg-1 {
    margin-left: -1px;
    margin-right: -1px;
  }
  .gutter-lg-1 div[class*="col"] {
    padding-left: 1px;
    padding-right: 1px;
  }
  .gutter-lg-2 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .gutter-lg-2 div[class*="col"] {
    padding-left: 2px;
    padding-right: 2px;
  }
  .gutter-lg-3 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .gutter-lg-3 div[class*="col"] {
    padding-left: 3px;
    padding-right: 3px;
  }
  .gutter-lg-4 {
    margin-left: -4px;
    margin-right: -4px;
  }
  .gutter-lg-4 div[class*="col"] {
    padding-left: 4px;
    padding-right: 4px;
  }
  .gutter-lg-5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .gutter-lg-5 div[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .gutter-lg-6 {
    margin-left: -6px;
    margin-right: -6px;
  }
  .gutter-lg-6 div[class*="col"] {
    padding-left: 6px;
    padding-right: 6px;
  }
  .gutter-lg-7 {
    margin-left: -7px;
    margin-right: -7px;
  }
  .gutter-lg-7 div[class*="col"] {
    padding-left: 7px;
    padding-right: 7px;
  }
  .gutter-lg-8 {
    margin-left: -8px;
    margin-right: -8px;
  }
  .gutter-lg-8 div[class*="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .gutter-lg-9 {
    margin-left: -9px;
    margin-right: -9px;
  }
  .gutter-lg-9 div[class*="col"] {
    padding-left: 9px;
    padding-right: 9px;
  }
  .gutter-lg-10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .gutter-lg-10 div[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gutter-lg-11 {
    margin-left: -11px;
    margin-right: -11px;
  }
  .gutter-lg-11 div[class*="col"] {
    padding-left: 11px;
    padding-right: 11px;
  }
  .gutter-lg-12 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .gutter-lg-12 div[class*="col"] {
    padding-left: 12px;
    padding-right: 12px;
  }
  .gutter-lg-13 {
    margin-left: -13px;
    margin-right: -13px;
  }
  .gutter-lg-13 div[class*="col"] {
    padding-left: 13px;
    padding-right: 13px;
  }
  .gutter-lg-14 {
    margin-left: -14px;
    margin-right: -14px;
  }
  .gutter-lg-14 div[class*="col"] {
    padding-left: 14px;
    padding-right: 14px;
  }
  .gutter-lg-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .gutter-lg-15 div[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .gutter-xl-0 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .gutter-xl-0 div[class*="col"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  .gutter-xl-1 {
    margin-left: -1px;
    margin-right: -1px;
  }
  .gutter-xl-1 div[class*="col"] {
    padding-left: 1px;
    padding-right: 1px;
  }
  .gutter-xl-2 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .gutter-xl-2 div[class*="col"] {
    padding-left: 2px;
    padding-right: 2px;
  }
  .gutter-xl-3 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .gutter-xl-3 div[class*="col"] {
    padding-left: 3px;
    padding-right: 3px;
  }
  .gutter-xl-4 {
    margin-left: -4px;
    margin-right: -4px;
  }
  .gutter-xl-4 div[class*="col"] {
    padding-left: 4px;
    padding-right: 4px;
  }
  .gutter-xl-5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .gutter-xl-5 div[class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .gutter-xl-6 {
    margin-left: -6px;
    margin-right: -6px;
  }
  .gutter-xl-6 div[class*="col"] {
    padding-left: 6px;
    padding-right: 6px;
  }
  .gutter-xl-7 {
    margin-left: -7px;
    margin-right: -7px;
  }
  .gutter-xl-7 div[class*="col"] {
    padding-left: 7px;
    padding-right: 7px;
  }
  .gutter-xl-8 {
    margin-left: -8px;
    margin-right: -8px;
  }
  .gutter-xl-8 div[class*="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .gutter-xl-9 {
    margin-left: -9px;
    margin-right: -9px;
  }
  .gutter-xl-9 div[class*="col"] {
    padding-left: 9px;
    padding-right: 9px;
  }
  .gutter-xl-10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .gutter-xl-10 div[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gutter-xl-11 {
    margin-left: -11px;
    margin-right: -11px;
  }
  .gutter-xl-11 div[class*="col"] {
    padding-left: 11px;
    padding-right: 11px;
  }
  .gutter-xl-12 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .gutter-xl-12 div[class*="col"] {
    padding-left: 12px;
    padding-right: 12px;
  }
  .gutter-xl-13 {
    margin-left: -13px;
    margin-right: -13px;
  }
  .gutter-xl-13 div[class*="col"] {
    padding-left: 13px;
    padding-right: 13px;
  }
  .gutter-xl-14 {
    margin-left: -14px;
    margin-right: -14px;
  }
  .gutter-xl-14 div[class*="col"] {
    padding-left: 14px;
    padding-right: 14px;
  }
  .gutter-xl-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .gutter-xl-15 div[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mxw-0 {
  max-width: 0px;
}

.mxw-1 {
  max-width: 50px;
}

.mxw-2 {
  max-width: 100px;
}

.mxw-3 {
  max-width: 150px;
}

.mxw-4 {
  max-width: 200px;
}

.mxw-5 {
  max-width: 250px;
}

.mxw-6 {
  max-width: 300px;
}

.mxw-7 {
  max-width: 350px;
}

.mxw-8 {
  max-width: 400px;
}

.mxw-9 {
  max-width: 450px;
}

.mxw-10 {
  max-width: 500px;
}

.mxw-11 {
  max-width: 550px;
}

.mxw-12 {
  max-width: 600px;
}

.mxw-13 {
  max-width: 650px;
}

.mxw-14 {
  max-width: 700px;
}

.mxw-15 {
  max-width: 750px;
}

.mxw-16 {
  max-width: 800px;
}

.mxw-17 {
  max-width: 850px;
}

.mxw-18 {
  max-width: 900px;
}

.mxw-19 {
  max-width: 950px;
}

.mxw-20 {
  max-width: 1000px;
}

@media (min-width: 575px) {
  .mxw-sm-0 {
    max-width: 0px;
  }
  .mxw-sm-1 {
    max-width: 50px;
  }
  .mxw-sm-2 {
    max-width: 100px;
  }
  .mxw-sm-3 {
    max-width: 150px;
  }
  .mxw-sm-4 {
    max-width: 200px;
  }
  .mxw-sm-5 {
    max-width: 250px;
  }
  .mxw-sm-6 {
    max-width: 300px;
  }
  .mxw-sm-7 {
    max-width: 350px;
  }
  .mxw-sm-8 {
    max-width: 400px;
  }
  .mxw-sm-9 {
    max-width: 450px;
  }
  .mxw-sm-10 {
    max-width: 500px;
  }
  .mxw-sm-11 {
    max-width: 550px;
  }
  .mxw-sm-12 {
    max-width: 600px;
  }
  .mxw-sm-13 {
    max-width: 650px;
  }
  .mxw-sm-14 {
    max-width: 700px;
  }
  .mxw-sm-15 {
    max-width: 750px;
  }
  .mxw-sm-16 {
    max-width: 800px;
  }
  .mxw-sm-17 {
    max-width: 850px;
  }
  .mxw-sm-18 {
    max-width: 900px;
  }
  .mxw-sm-19 {
    max-width: 950px;
  }
  .mxw-sm-20 {
    max-width: 1000px;
  }
}

@media (min-width: 768px) {
  .mxw-md-0 {
    max-width: 0px;
  }
  .mxw-md-1 {
    max-width: 50px;
  }
  .mxw-md-2 {
    max-width: 100px;
  }
  .mxw-md-3 {
    max-width: 150px;
  }
  .mxw-md-4 {
    max-width: 200px;
  }
  .mxw-md-5 {
    max-width: 250px;
  }
  .mxw-md-6 {
    max-width: 300px;
  }
  .mxw-md-7 {
    max-width: 350px;
  }
  .mxw-md-8 {
    max-width: 400px;
  }
  .mxw-md-9 {
    max-width: 450px;
  }
  .mxw-md-10 {
    max-width: 500px;
  }
  .mxw-md-11 {
    max-width: 550px;
  }
  .mxw-md-12 {
    max-width: 600px;
  }
  .mxw-md-13 {
    max-width: 650px;
  }
  .mxw-md-14 {
    max-width: 700px;
  }
  .mxw-md-15 {
    max-width: 750px;
  }
  .mxw-md-16 {
    max-width: 800px;
  }
  .mxw-md-17 {
    max-width: 850px;
  }
  .mxw-md-18 {
    max-width: 900px;
  }
  .mxw-md-19 {
    max-width: 950px;
  }
  .mxw-md-20 {
    max-width: 1000px;
  }
}

@media (min-width: 992px) {
  .mxw-lg-0 {
    max-width: 0px;
  }
  .mxw-lg-1 {
    max-width: 50px;
  }
  .mxw-lg-2 {
    max-width: 100px;
  }
  .mxw-lg-3 {
    max-width: 150px;
  }
  .mxw-lg-4 {
    max-width: 200px;
  }
  .mxw-lg-5 {
    max-width: 250px;
  }
  .mxw-lg-6 {
    max-width: 300px;
  }
  .mxw-lg-7 {
    max-width: 350px;
  }
  .mxw-lg-8 {
    max-width: 400px;
  }
  .mxw-lg-9 {
    max-width: 450px;
  }
  .mxw-lg-10 {
    max-width: 500px;
  }
  .mxw-lg-11 {
    max-width: 550px;
  }
  .mxw-lg-12 {
    max-width: 600px;
  }
  .mxw-lg-13 {
    max-width: 650px;
  }
  .mxw-lg-14 {
    max-width: 700px;
  }
  .mxw-lg-15 {
    max-width: 750px;
  }
  .mxw-lg-16 {
    max-width: 800px;
  }
  .mxw-lg-17 {
    max-width: 850px;
  }
  .mxw-lg-18 {
    max-width: 900px;
  }
  .mxw-lg-19 {
    max-width: 950px;
  }
  .mxw-lg-20 {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .mxw-xl-0 {
    max-width: 0px;
  }
  .mxw-xl-1 {
    max-width: 50px;
  }
  .mxw-xl-2 {
    max-width: 100px;
  }
  .mxw-xl-3 {
    max-width: 150px;
  }
  .mxw-xl-4 {
    max-width: 200px;
  }
  .mxw-xl-5 {
    max-width: 250px;
  }
  .mxw-xl-6 {
    max-width: 300px;
  }
  .mxw-xl-7 {
    max-width: 350px;
  }
  .mxw-xl-8 {
    max-width: 400px;
  }
  .mxw-xl-9 {
    max-width: 450px;
  }
  .mxw-xl-10 {
    max-width: 500px;
  }
  .mxw-xl-11 {
    max-width: 550px;
  }
  .mxw-xl-12 {
    max-width: 600px;
  }
  .mxw-xl-13 {
    max-width: 650px;
  }
  .mxw-xl-14 {
    max-width: 700px;
  }
  .mxw-xl-15 {
    max-width: 750px;
  }
  .mxw-xl-16 {
    max-width: 800px;
  }
  .mxw-xl-17 {
    max-width: 850px;
  }
  .mxw-xl-18 {
    max-width: 900px;
  }
  .mxw-xl-19 {
    max-width: 950px;
  }
  .mxw-xl-20 {
    max-width: 1000px;
  }
}

.background-primary {
  background-color: #2d825b;
}

.background-secondary {
  background-color: #2d825b;
}

.background-gray {
  background-color: #f6f6f6;
}

.background-darkgray {
  background-color: #cccccc;
}

.background-white {
  background-color: #ffffff;
}

.background-black {
  background-color: #ffffff;
}

.color-black {
  color: #000000;
}

.color-white {
  color: #ffffff;
}

#wpforms-122,
.form-group {
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  color: #2d825b;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
}

#wpforms-122 label,
.form-group label {
  display: block;
  margin-bottom: .75rem;
  font-weight: inherit;
  font-size: inherit;
}

#wpforms-122 .form-control,
#wpforms-122 input,
#wpforms-122 textarea,
.form-group .form-control,
.form-group input,
.form-group textarea {
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  border: 1px solid #2d825b;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  background-color: transparent;
  color: #2d825b;
}

#wpforms-122 .form-control::placeholder,
#wpforms-122 input::placeholder,
#wpforms-122 textarea::placeholder,
.form-group .form-control::placeholder,
.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #2d825b;
}

#wpforms-122 textarea,
#wpforms-122 textarea.form-control,
.form-group textarea,
.form-group textarea.form-control {
  height: 10rem;
  padding: 1rem;
  resize: none;
}

#wpforms-122 .btn,
.form-group .btn {
  margin: inherit;
}

#wpforms-122 .btn,
.form-group .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 8rem;
  margin: auto;
  padding: 0.45rem 1.5rem 0.3rem;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  z-index: 0;
  text-align: center;
  background-color: #2d825b;
  color: #ffffff;
  border-color: #2d825b;
  border-radius: 0;
  border: solid 1px transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1rem;
  -webkit-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

#wpforms-122 .btn:hover,
.form-group .btn:hover {
  box-shadow: none;
  background-color: #ffffff;
  color: #2d825b;
  border-color: #2d825b;
}

.form-group {
  margin-bottom: 1rem;
}

.bk-list ul {
  padding-left: 0;
}

.bk-list li {
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.8rem;
  list-style: none;
}

.bk-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 9px;
  height: 12px;
  background-color: #99cc33;
}

.bk-plan {
  padding: 2.5rem 1.2rem;
  color: #ffffff;
  position: relative;
  text-align: center;
  background: #2d825b;
  background: -moz-linear-gradient(-45deg, #2d825b 0%, #689f38 100%);
  background: -webkit-linear-gradient(-45deg, #2d825b 0%, #689f38 100%);
  background: linear-gradient(135deg, #2d825b 0%, #689f38 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d825b', endColorstr='#689f38',GradientType=1 );
}

@media (min-width: 768px) {
  .bk-plan {
    padding: 2.5rem 2rem;
  }
}

.bk-plan:before {
  content: "";
  background: url("../images/plan-bg.jpg") no-repeat center/cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .2;
}

.bk-plan.bk-2:before {
  background: url("../images/plan-bg-2.jpg") no-repeat center/cover;
}

.bk-plan.bk-3:before {
  background: url("../images/plan-bg-3.jpg") no-repeat center/cover;
}

.bk-plan .inner-bk {
  position: relative;
  z-index: 2;
}

.bk-plan .title-bk {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

@media (min-width: 1200px) {
  .bk-plan .title-bk {
    font-size: 1.3rem;
  }
}

@media (min-width: 1367px) {
  .bk-plan .title-bk {
    font-size: 1.4rem;
  }
}

.bk-plan .price-bk {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 3rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .bk-plan .price-bk {
    font-size: 1.8rem;
  }
}

@media (min-width: 1367px) {
  .bk-plan .price-bk {
    font-size: 1.9rem;
  }
}

.bk-plan .price-bk span {
  font-size: 3rem;
  font-weight: 700;
}

@media (min-width: 1200px) {
  .bk-plan .price-bk span {
    font-size: 3.5rem;
  }
}

@media (min-width: 1367px) {
  .bk-plan .price-bk span {
    font-size: 4rem;
  }
}

.bk-plan ul {
  margin-bottom: 2.8rem;
  padding-left: 0;
}

.bk-plan li {
  list-style: none;
  margin-bottom: 1.8rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid #ffffff;
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
}

@media (min-width: 1200px) {
  .bk-plan li {
    font-size: 1.1rem;
  }
}

@media (min-width: 1367px) {
  .bk-plan li {
    font-size: 1.2rem;
  }
}

.bk-plan .btn {
  border: none;
  background-color: #ffffff;
  color: #2d825b;
  font-size: 1rem;
  padding: .4rem 1rem;
}

.bk-plan .btn:hover {
  background-color: #2d825b;
  color: #ffffff;
}

.bk-offer {
  background-color: rgba(126, 155, 60, 0.21);
  padding: 3rem 2rem;
  height: 100%;
  color: #2d825b;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  font-weight: 400;
}

.bk-offer:before {
  content: "";
  background: url("../images/logo-icon.svg") no-repeat center/contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 50%;
  opacity: .1;
}

.bk-offer .title-bk {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  color: #2d825b;
  background-color: #ffffff;
  padding: .5rem .8rem;
}

@media (min-width: 768px) {
  .bk-offer .title-bk {
    padding: .8rem 1rem;
  }
}

@media (min-width: 1681px) {
  .bk-offer .title-bk {
    font-size: 1.7rem;
  }
}

.bk-offer .offer-bk {
  font-size: 1.1rem;
}

@media (min-width: 575px) {
  .bk-offer .offer-bk {
    font-size: 1.3rem;
  }
}

.bk-offer .price-bk {
  font-size: 1.1rem;
  font-weight: 700;
}

@media (min-width: 575px) {
  .bk-offer .price-bk {
    font-size: 1.3rem;
  }
}

.bk-offer .bk-benefit {
  margin: 2rem 0px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.bk-offer .bk-benefit li {
  margin-bottom: .3rem;
}

.bk-offer .bk-benefit li:last-child {
  margin-bottom: 0;
}

.bk-offer ul {
  margin: 0;
  padding: 0;
}

.bk-offer li {
  list-style: none;
}

.bk-offer .bk-list li {
  padding-left: 2.2rem;
}

.bk-offer .bk-list li:last-child {
  margin-bottom: 0;
}

.bk-offer .bk-list li:before {
  background: url("../images/logo-icon.svg") no-repeat center/cover;
  width: 17px;
  height: 23px;
  top: 1px;
}

.bk-accordion .card {
  margin-bottom: 1.2rem;
  border-radius: 0;
  border: none;
}

.bk-accordion .card-header {
  padding: 0;
  border: none;
}

.bk-accordion .card-body {
  border: 1px solid #e4ead6;
  border-top: 0;
  line-height: 1.8rem;
  padding: 2rem 1rem;
}

@media (min-width: 768px) {
  .bk-accordion .card-body {
    padding: 2rem;
  }
}

.bk-accordion .btn-link {
  width: 100%;
  border: none;
  text-align: left;
  padding: 1rem 3rem 1rem 1rem;
  outline: 0;
  border-radius: 0;
  text-decoration: none;
  background-color: #e4ead6;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: normal;
}

@media (min-width: 992px) {
  .bk-accordion .btn-link {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .bk-accordion .btn-link {
    padding: 1rem 4rem 1rem 2rem;
  }
}

.bk-accordion .btn-link:after {
  content: "";
  position: absolute;
  background: url("../images/down.svg") no-repeat center/cover;
  width: 18px;
  height: 10px;
  right: 1.2rem;
  top: 1.5rem;
  animation: none;
  transform: rotate(180deg);
  transition: all .2s ease;
}

@media (min-width: 768px) {
  .bk-accordion .btn-link:after {
    width: 23px;
    height: 12px;
    right: 2rem;
    top: 1.6rem;
  }
}

.bk-accordion .btn-link.collapsed:after {
  transform: rotate(0deg);
}

body.admin-bar .bk-header {
  top: 32px;
}

@media screen and (max-width: 782px) {
  body.admin-bar .bk-header {
    top: 46px;
  }
}

.bk-header {
  width: 100%;
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  transition: 0.4s;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
}

@media (min-width: 1200px) {
  .bk-header {
    font-size: 1.1rem;
  }
}

@media (min-width: 1367px) {
  .bk-header {
    font-size: 1.2rem;
  }
}

@media (max-width: 1199px) {
  .bk-header {
    padding: 0.8rem 0;
  }
}

.bk-header.fixed .header-bk {
  position: fixed;
}

.bk-header.active {
  left: -280px;
}

@media (min-width: 1200px) {
  .bk-header.active {
    left: 0;
  }
}

.bk-header a {
  transition: 0.4s;
  text-transform: capitalize;
}

.bk-header a:not(.btn):not(.obj):not(.button)::after {
  display: none;
}

.bk-header nav a {
  color: #006838;
  font-weight: 700;
}

.bk-header nav a:hover {
  color: #99cc33;
}

.bk-header nav .current_page_item a {
  color: #99cc33;
}

.bk-header nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bk-header nav > ul > li {
  margin: 0;
  padding: 2.5rem 1.8rem;
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 1680px) {
  .bk-header nav > ul > li {
    padding: 2rem 1.2rem;
  }
}

.bk-header nav > ul > li.menu-item-has-children > a:after {
  content: url("../images/down-arrow.svg");
  display: inline-block !important;
  width: 0.9rem;
  vertical-align: 2px;
  margin-left: 5px;
}

.bk-header nav > ul > li.menu-item-has-children:hover > a {
  color: #2d825b;
}

.bk-header nav > ul > li.menu-item-has-children:hover > a:after {
  content: url("../images/green-down-arrow.svg");
}

.bk-header nav > ul > li.menu-item-has-children:hover ul.sub-menu {
  display: block;
}

.bk-header .logo-bk {
  padding-right: 1.8rem;
}

@media (max-width: 1680px) {
  .bk-header .logo-bk {
    padding-right: 1rem;
  }
}

.bk-header .logo-bk a {
  display: block;
}

.bk-header .logo-bk img {
  width: auto;
  height: 3rem;
}

@media (min-width: 768px) {
  .bk-header .logo-bk img {
    height: 3.3rem;
  }
}

@media (max-width: 1199px) {
  .bk-header .right-bk {
    margin-left: auto;
  }
}

.bk-header .right-bk ul {
  margin-bottom: 0;
  padding-left: 0;
}

.bk-header .right-bk ul li {
  padding-left: 1.8rem;
}

@media (max-width: 574px) {
  .bk-header .right-bk ul li {
    padding-left: 1.2rem;
  }
}

.bk-header .right-bk .btn:hover {
  color: #2d825b;
}

.bk-header.fixed-top {
  position: fixed;
  width: 100%;
  background-color: #fff;
  -webkit-animation: 0.7s fade-in;
  animation: 0.7s fade-in;
  z-index: 999;
  animation: 0.7s ease-in-out 0.1s normal both 1 running stickyhead;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
}

@keyframes stickyhead {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.bk-header li.menu-item-has-children ul.sub-menu {
  display: none;
  white-space: nowrap;
  min-width: 12rem;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  font-weight: 400;
  background-color: #ffffff;
  box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  transform: translateY(-1px);
}

.bk-header li.menu-item-has-children ul.sub-menu li {
  width: 100%;
  margin: 0;
  padding: 0.25rem 0;
  position: relative;
  transition: 0.4s;
}

.bk-header li.menu-item-has-children ul.sub-menu li:hover {
  background-color: #2d825b;
}

.bk-header li.menu-item-has-children ul.sub-menu li:hover > ul {
  display: block;
}

.bk-header li.menu-item-has-children ul.sub-menu li:hover > a {
  color: #ffffff;
}

.bk-header li.menu-item-has-children ul.sub-menu li a {
  display: block;
  padding: 0.5rem 1rem;
  text-transform: none;
}

.bk-header li.menu-item-has-children ul.sub-menu li a::after {
  display: none;
}

.bk-header li.menu-item-has-children ul.sub-menu li ul {
  display: none;
  min-width: 12rem;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-1px);
  background-color: #ffffff;
  box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1);
}

.bk-header aside .show-sm {
  display: none;
}

@media (min-width: 575px) {
  .bk-header aside .show-sm {
    display: flex;
  }
}

.bk-inner-header {
  border-bottom: 1px solid #e7e7e7;
}

.bk-footer {
  background-color: #2d825b;
  color: #ffffff;
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .bk-footer {
    font-size: 1.1rem;
  }
}

@media (min-width: 1367px) {
  .bk-footer {
    font-size: 1.2rem;
  }
}

@media (max-width: 574px) {
  .bk-footer {
    text-align: center;
  }
}

.bk-footer ::selection {
  background-color: #99cc33;
}

.bk-footer li {
  list-style: none;
}

.bk-footer a {
  color: #ffffff;
}

.bk-footer a path {
  transition: all .2s ease;
}

.bk-footer a:hover {
  color: #99cc33;
}

.bk-footer a:hover path {
  fill: #99cc33;
}

.bk-footer ul {
  padding-left: 0;
  margin-bottom: 0;
}

.bk-footer .title-bk {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
  color: #ffffff;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .bk-footer .title-bk {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
  .bk-footer .title-bk {
    font-size: 1.25rem;
  }
}

@media (min-width: 1367px) {
  .bk-footer .title-bk {
    font-size: 1.3rem;
  }
}

.bk-footer .nav-bk {
  width: 100%;
  margin-left: inherit;
}

@media (min-width: 992px) {
  .bk-footer .nav-bk {
    margin-left: auto;
    width: max-content;
  }
}

@media (max-width: 574px) {
  .bk-footer .nav-bk {
    text-align: center;
  }
}

.bk-footer .nav-bk li {
  margin-bottom: 1.1rem;
}

.bk-footer .apps-bk img {
  height: 3rem;
}

@media (max-width: 991px) {
  .bk-footer .bottom-bk {
    text-align: center;
  }
}

.bk-footer .bottom-bk ul {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .bk-footer .bottom-bk ul {
    justify-content: center;
  }
}

.bk-footer .bottom-bk li {
  padding-right: 1.4rem;
}

.bk-footer .bottom-bk li:last-child {
  padding-right: 0;
}

.bk-footer .bottom-bk p {
  margin-bottom: 0;
}

.bk-footer .social-bk {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .bk-footer .social-bk {
    justify-content: flex-end;
  }
}

.bk-footer .social-bk a {
  margin-left: 1rem;
}

.bk-footer .social-bk a:first-child {
  margin-left: 0;
}

.bk-footer p img {
  height: 1.1rem;
  width: auto;
}

.newsletter-subscription-bk {
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
  padding-top: 5px;
}

@media (min-width: 575px) {
  .newsletter-subscription-bk {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}

.newsletter-subscription-bk form {
  border-bottom: 1px solid #ffffff;
}

.newsletter-subscription-bk form .input {
  width: calc( 100% - 2rem);
}

.newsletter-subscription-bk form .input input {
  border: none;
  height: 3rem;
  background-color: transparent;
  width: 100%;
}

.newsletter-subscription-bk form .input input:focus {
  outline: none;
}

.newsletter-subscription-bk form .input input::placeholder {
  color: #fff;
}

.newsletter-subscription-bk form .btn-arrow {
  width: 2rem;
  height: 3rem;
  border: none;
  outline: none;
  position: relative;
  background-color: transparent;
}

.newsletter-subscription-bk form .btn-arrow:hover svg {
  fill: #ffffff;
}

.newsletter-subscription-bk form .btn-arrow svg {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translatey(-50%);
  width: 100%;
  height: auto;
  transition: .4s;
  fill: #ffffff;
  z-index: 1;
}

.newsletter-subscription-bk form .btn-arrow input {
  border: none;
  height: 3rem;
  background-color: transparent;
  width: 100%;
  color: #fff;
  position: relative;
  z-index: 2;
  opacity: 0;
}

.newsletter-subscription-bk #mc_embed_signup form #mce-responses {
  position: absolute;
  top: 100%;
  margin-top: 1rem;
  color: #000;
  background-color: #fff;
  left: 50%;
  transform: translatex(-50%);
  width: 100%;
  min-width: 220px;
}

.newsletter-subscription-bk #mc_embed_signup form #mce-responses .response {
  padding: .5rem 1rem;
  border: 1px solid #13bf98;
}

.newsletter-subscription-bk #mc_embed_signup form .mce_inline_error {
  position: absolute;
  top: 100%;
  margin-top: 1rem !important;
  left: 50%;
  transform: translatex(-50%);
  width: 100%;
}

.bk-wrapper {
  width: 100%;
  overflow: hidden;
}

.bk-wrapper.active .bk-ui {
  right: 280px;
}

@media (min-width: 1200px) {
  .bk-wrapper.active .bk-ui {
    right: 0;
  }
}

.bk-wrapper .bk-ui {
  position: relative;
  right: 0;
  transition: .4s;
}

.bk-mob-tog {
  color: inherit;
  position: relative;
  height: 3rem;
  margin-left: .8rem;
  padding: 0 0 0 2rem;
  outline: 0 !important;
  color: #2d825b;
}

@media (min-width: 575px) {
  .bk-mob-tog {
    margin-left: 1.5rem;
    padding-left: 2rem;
  }
}

.bk-mob-tog.active .bk-burger-menu::before {
  transform: translate(-50%, -50%) translateY(0) rotate(-45deg);
}

.bk-mob-tog.active .bk-burger-menu::after {
  transform: translate(-50%, -50%) translateY(0) rotate(45deg);
}

.bk-mob-tog .bk-burger-menu {
  width: 2.2rem;
  height: .4rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.bk-mob-tog .bk-burger-menu::before, .bk-mob-tog .bk-burger-menu::after {
  width: .8rem;
  height: 1px;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #2d825b;
  transition: transform .4s;
}

@media (min-width: 575px) {
  .bk-mob-tog .bk-burger-menu::before, .bk-mob-tog .bk-burger-menu::after {
    width: 1.2rem;
    height: 2px;
  }
}

.bk-mob-tog .bk-burger-menu::before {
  transform: translate(-50%, -50%) translateY(-2px) rotate(0);
}

@media (min-width: 575px) {
  .bk-mob-tog .bk-burger-menu::before {
    transform: translate(-50%, -50%) translateY(-3px) rotate(0);
  }
}

.bk-mob-tog .bk-burger-menu::after {
  transform: translate(-50%, -50%) translateY(2px) rotate(0);
}

@media (min-width: 575px) {
  .bk-mob-tog .bk-burger-menu::after {
    transform: translate(-50%, -50%) translateY(3px) rotate(0);
  }
}

.bk-mob-tog span {
  display: block;
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 600;
}

@media (min-width: 575px) {
  .bk-mob-tog span {
    font-size: .9rem;
    margin-left: 1.5rem;
  }
}

.bk-mob-nav-mask {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 320;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  transition: .4s;
}

.bk-mob-nav-mask.active {
  transform: translateX(0);
}

.bk-mob-nav {
  background-color: #2d825b;
  color: #646464;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -280px;
  z-index: 321;
  transition: 0.4s;
  z-index: 999999;
}

.bk-mob-nav.active {
  right: 0;
}

.bk-mob-nav .contain-bk {
  width: 100%;
  height: 100%;
  padding: 1.2rem 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.bk-mob-nav a {
  display: inline-block;
  color: #ffffff;
}

.bk-mob-nav a::after {
  background-color: #ffffff;
}

.bk-mob-nav a:hover {
  color: #ffffff;
}

.bk-mob-nav .current_page_item a {
  color: #99cc33;
}

.bk-mob-nav .grid-bk {
  text-align: center;
}

.bk-mob-nav .grid-bk ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bk-mob-nav .grid-bk ul li {
  width: 50%;
  position: relative;
  margin: 0;
  padding: 0;
}

.bk-mob-nav .grid-bk ul li a {
  display: block;
  background-color: #000000;
  text-transform: uppercase;
  padding: 1rem .5rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.bk-mob-nav .grid-bk ul li a.active {
  background-color: transparent;
}

.bk-mob-nav .list-bk ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bk-mob-nav .list-bk ul li {
  position: relative;
  margin: 0;
  padding: .5rem 0;
}

.bk-mob-nav .list-bk ul li:first-child {
  border-top: none;
}

.bk-mob-nav .list-bk ul li a {
  position: relative;
  margin-left: 1.5rem;
  margin-right: 3rem;
  padding: .25rem 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  font-size: .9rem;
  font-weight: 500;
  text-transform: uppercase;
}

.bk-mob-nav .list-bk ul li ul {
  display: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #646464;
}

.bk-mob-nav .list-bk ul li ul li {
  border-top: none;
}

.bk-mob-nav .list-bk ul li ul li button {
  background-color: #2d825b;
  color: #646464;
}

.bk-mob-nav .list-bk ul li ul li ul {
  margin-left: 1rem;
  background-color: #2d825b;
  color: #646464;
}

.bk-mob-nav .list-bk ul li ul li ul ul {
  background-color: rgba(255, 255, 255, 0.1);
}

.bk-mob-nav .list-bk ul li ul li a {
  position: relative;
  text-transform: none;
}

.bk-mob-nav .list-bk .active > button::before {
  transform: translate(-50%, -50%) rotate(180deg);
}

.bk-mob-nav .list-bk button {
  cursor: pointer;
  display: block;
  width: 3rem;
  height: 100%;
  max-height: 3.4rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  z-index: 4;
  background-color: transparent;
  color: #ffffff;
  transition: .4s;
}

.bk-mob-nav .list-bk button::before {
  content: url("../images/white-down-arrow.svg");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  transition: .4s;
}

.bk-mob-nav address {
  margin: auto 0 0 0;
  padding: 2rem 0 3rem;
  font-style: normal;
}

.bk-mob-nav address p {
  border-top: solid 1px #ebebeb;
  font-weight: 300;
  padding: 1.5rem 0 0;
}

.bk-mob-nav .social-bk {
  display: block;
  text-align: center;
  border-top: solid 1px #ebebeb;
  padding: 1.5rem 0 0 0;
  margin: 1.5rem 0 0;
}

.bk-mob-nav .social-bk a {
  color: #ffffff;
  height: 20px;
  margin: 0 .5rem;
  font-size: 1.2rem;
}

.bk-mob-nav .social-bk a:hover {
  color: #ffffff;
}

.bk-mob-nav .contact-bk {
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
}

.bk-mob-nav .contact-bk a {
  margin-left: 1.5rem;
}

.bk-mob-nav .contact-bk a:first-child {
  margin-left: 0;
}

.bk-get-quote {
  position: fixed;
  bottom: 1rem;
  right: 1.2rem;
  z-index: 99;
}

.bk-get-quote .btn {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  padding: .3rem;
  justify-content: center;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0);
  min-width: auto;
}

.bk-get-quote .btn svg {
  margin: 0;
}

.bk-banner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  color: #ffffff;
  padding: 2.5rem 0 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 575px) {
  .bk-banner {
    padding: 3rem 0 0;
  }
}

@media (min-width: 768px) {
  .bk-banner {
    padding: 4rem 0 0;
  }
}

@media (max-width: 1199px) {
  .bk-banner {
    min-height: 100% !important;
  }
}

.bk-banner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

@media (min-width: 992px) {
  .bk-banner:before {
    display: none;
  }
}

.bk-banner .video-bk {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.bk-banner .video-bk video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.bk-banner .video-bk:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #163c2a;
  opacity: .7;
}

.bk-banner .playpause-holder {
  height: 3rem;
  position: relative;
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .bk-banner .playpause-holder {
    display: none;
  }
}

.bk-banner .playpause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.bk-banner .playpause svg {
  height: 3rem;
  width: 3rem;
  fill: #fff;
}

.bk-banner h1 {
  color: #ffffff;
  font-weight: 800;
  font-size: 2.3rem;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .bk-banner h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .bk-banner h1 {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .bk-banner h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1367px) {
  .bk-banner h1 {
    font-size: 2.8rem;
  }
}

.bk-banner:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: .3rem;
  background-color: #ffffff;
  z-index: 1;
}

@media (min-width: 575px) {
  .bk-banner:after {
    height: .5rem;
    display: block;
  }
}

@media (min-width: 992px) {
  .bk-banner:after {
    height: 1.2rem;
  }
}

.bk-banner .container-fluid {
  position: relative;
  z-index: 2;
}

@media (max-width: 991px) {
  .bk-banner .container-fluid {
    display: flex;
    flex-direction: column;
  }
}

.bk-banner article {
  max-width: 32rem;
  text-align: center;
  margin: 0 auto 2rem;
}

@media (min-width: 768px) {
  .bk-banner article {
    margin: 0 auto 2.8rem;
  }
}

@media (min-width: 992px) {
  .bk-banner article {
    position: absolute;
    left: 50%;
    bottom: 19%;
    transform: translateX(-50%);
    margin: inherit;
    max-width: 30rem;
  }
}

@media (min-width: 1200px) {
  .bk-banner article {
    left: 50%;
    bottom: 18%;
    max-width: 34rem;
  }
}

@media (min-width: 1367px) {
  .bk-banner article {
    max-width: 38rem;
  }
}

@media (max-width: 991px) {
  .bk-banner article {
    order: 1;
  }
}

.bk-banner figure {
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  position: relative;
  margin-bottom: 0;
  width: 90%;
  max-width: 400px;
}

@media (min-width: 992px) {
  .bk-banner figure {
    transform: translateX(-42%);
    width: 90%;
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .bk-banner figure {
    left: 50%;
    width: 100%;
    width: max-content;
  }
}

@media (max-width: 991px) {
  .bk-banner figure {
    order: 2;
  }
}

@media (min-width: 1200px) {
  .bk-banner figure img {
    height: 34rem;
  }
}

@media (min-width: 1367px) {
  .bk-banner figure img {
    height: 37rem;
  }
}

.bk-banner p {
  font-size: 1.2rem;
  line-height: 1;
}

@media (min-width: 1367px) {
  .bk-banner p {
    font-size: 1.4rem;
  }
}

.bk-banner .btn {
  font-size: 1.2rem;
  border: none;
  padding: .6rem 1.5rem;
}

.bk-banner .js-scroll-bk {
  animation: bounce 2s infinite;
  cursor: pointer;
}

@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bk-management .bk-list ul {
  display: flex;
  flex-wrap: wrap;
}

.bk-management .bk-list li {
  width: 100%;
}

@media (min-width: 1200px) {
  .bk-management .bk-list li {
    width: 50%;
  }
}

.bk-book {
  max-width: max-content;
  margin: 0 auto;
  position: relative;
}

.bk-book figure {
  margin-bottom: 0;
}

.bk-book .details-bk {
  position: absolute;
  left: 2.2rem;
  top: 7rem;
  width: 76%;
}

@media (min-width: 400px) {
  .bk-book .details-bk {
    left: 2.8rem;
    top: 9rem;
  }
}

.bk-book .title-bk {
  font-size: 1.3rem;
  font-weight: 400;
  color: #2d825b;
  text-transform: capitalize;
  margin-bottom: 0;
}

@media (min-width: 400px) {
  .bk-book .title-bk {
    font-size: 1.5rem;
  }
}

.bk-book address {
  color: #113561;
  font-size: .8rem;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
}

@media (min-width: 340px) {
  .bk-book address {
    font-size: 1rem;
  }
}

.bk-book .input-bk {
  margin-bottom: 0.6rem;
  color: #2d825b;
}

.bk-book .input-bk label {
  width: 100%;
  margin-bottom: 0;
  background-color: #f5f6f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.bk-book .input-bk label span:first-child {
  width: 50%;
}

.bk-book .input-bk label span:nth-child(2) {
  width: calc(40% - 9px);
}

.bk-book .input-bk label span:nth-child(3) {
  width: 9px;
}

.bk-book .input-bk input[type=checkbox] {
  opacity: 0;
  height: 0;
  position: absolute;
}

.bk-book .input-bk input[type=checkbox]:checked ~ label {
  background-color: #2d825b;
  color: #ffffff;
}

.bk-book .input-bk input[type=checkbox]:checked ~ label path {
  fill: #ffffff;
}

.bk-book .buttons-bk {
  display: flex;
  align-items: center;
  margin: 1.8rem 0;
}

.bk-book .buttons-bk button:not(.btn) {
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
}

.bk-book .buttons-bk button {
  margin-right: 1rem;
  margin-left: 0;
}

.bk-book .amounts-bk {
  display: flex;
  align-items: flex-start;
  margin: 0 -7px;
}

.bk-book .amounts-bk input {
  width: 100%;
  height: 2.5rem;
  outline: 0;
  border-radius: 0;
  border: 1px solid #2d825b;
  color: #2d825b;
  font-size: 1rem;
  padding: 0 .8rem;
}

.bk-book .amounts-bk input::placeholder {
  color: #2d825b;
}

.bk-book .amounts-bk label {
  font-size: .9rem;
  color: #2d825b;
  line-height: .9rem;
  margin-bottom: .5rem;
}

.bk-book .inner-bk {
  width: 50%;
  padding: 7px;
}

.bk-book .btn {
  font-size: 0.9rem;
}

@media (max-width: 400px) {
  .bk-book .btn {
    padding: .3rem 1rem;
  }
}

.bk-book .btn:before {
  width: 1.3rem;
  height: 1.8rem;
}

.bk-speciality {
  background: url("../images/speciality-bg.jpg") no-repeat center/cover;
  position: relative;
  padding: 3rem 0;
}

@media (min-width: 992px) {
  .bk-speciality {
    padding: 3rem 0 4rem;
  }
}

@media (min-width: 1200px) {
  .bk-speciality {
    padding: 4rem 0;
  }
}

@media (min-width: 1681px) {
  .bk-speciality {
    padding: 6rem 0 7rem;
  }
}

@media (max-width: 767px) {
  .bk-speciality {
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .bk-speciality h2 {
    color: #ffffff;
  }
}

.bk-speciality:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 122%;
  background: url("../images/shape1.svg") no-repeat center/cover;
  height: 26rem;
  display: none;
}

@media (min-width: 768px) {
  .bk-speciality:before {
    display: block;
  }
}

@media (min-width: 992px) {
  .bk-speciality:before {
    height: 26rem;
    width: 103%;
  }
}

@media (min-width: 1200px) {
  .bk-speciality:before {
    height: 33rem;
    width: 106%;
  }
}

@media (min-width: 1681px) {
  .bk-speciality:before {
    height: 39rem;
    width: 100%;
  }
}

.bk-speciality .container {
  position: relative;
  z-index: 2;
}

.bk-services {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .bk-services {
    padding-top: 13rem;
  }
}

@media (min-width: 992px) {
  .bk-services {
    padding-top: 17rem;
  }
}

@media (min-width: 1200px) {
  .bk-services {
    padding-top: 20rem;
  }
}

.bk-services .item-bk {
  width: 100%;
  background-color: #ffffff;
  padding: 2rem;
  color: #2d825b;
  margin-bottom: 3px;
  transition: all 0.2s ease;
  text-align: center;
}

@media (min-width: 575px) {
  .bk-services .item-bk {
    width: calc(100% / 2 - 2px);
  }
}

@media (min-width: 575px) {
  .bk-services .item-bk {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .bk-services .item-bk {
    width: calc(100% / 3 - 2px);
  }
}

.bk-services .item-bk path {
  fill: #2d825b;
}

.bk-services .item-bk:hover {
  background-color: #2d825b;
  color: #ffffff;
}

.bk-services .item-bk:hover path {
  fill: #ffffff;
}

.bk-services figure {
  height: 3.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .bk-services figure {
    text-align: right;
    margin-bottom: 1.5rem;
  }
}

.bk-services p {
  max-width: 20rem;
  margin: auto;
  font-weight: 400;
}

@media (min-width: 768px) {
  .bk-services p {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .bk-testimonial {
    text-align: center;
  }
}

.bk-testimonial .left-bk {
  overflow: hidden;
  border-radius: 1.5rem;
  padding-bottom: 60%;
}

.bk-testimonial .left-bk img {
  object-position: 81% 50%;
}

.bk-testimonial h1,
.bk-testimonial p {
  margin-bottom: 1.5rem;
}

.bk-testimonial .author-bk {
  font-size: 1.2rem;
  color: #2d825b;
  font-weight: 500;
}

.bk-choice {
  height: 30rem;
  position: relative;
}

.bk-choice:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .5;
  background-color: #163c2a;
  opacity: .7;
}

@media (min-width: 768px) {
  .bk-choice {
    height: 0rem;
    padding-bottom: 35%;
  }
}

.bk-choice .jarallax-img {
  object-position: 78% 50% !important;
}

@media (min-width: 1200px) {
  .bk-choice .jarallax-img {
    object-position: 50% 50% !important;
  }
}

.bk-choice .title-bk {
  color: #ffffff;
}

.bk-choice p {
  color: #ffffff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .bk-choice p {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1367px) {
  .bk-choice p {
    font-size: 1.4rem;
  }
}

.bk-choice .btn {
  margin: inherit;
}

.bk-line {
  position: absolute;
  top: 38%;
  z-index: -1;
}

@media (min-width: 1200px) {
  .bk-line {
    top: 26%;
  }
}

@media (min-width: 1681px) {
  .bk-line {
    top: 25%;
  }
}

@media (max-width: 767px) {
  .bk-line {
    display: none;
  }
}

.bk-contact .title-bk {
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  color: #2d825b;
  font-weight: 400;
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-size: 1.2rem;
}

@media (min-width: 1200px) {
  .bk-contact .title-bk {
    font-size: 1.3rem;
  }
}

.bk-contact p {
  color: #8a94a6;
}

@media (min-width: 575px) {
  .bk-login .right-bk {
    padding-left: 2.5rem;
  }
}

@media (min-width: 768px) {
  .bk-login .right-bk {
    border-left: 1px solid #2d825b;
    padding-left: 5rem;
    padding-bottom: 1.5rem;
  }
}

.bk-login .right-bk .title-bk {
  font-size: 1rem;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", sans-serif;
  color: #2d825b;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
}

.bk-login .right-bk .btn2 {
  margin: inherit;
  padding: 1rem 1rem;
  font-weight: 400;
}

.bk-login .right-bk .btn, .bk-login .right-bk .btn2 {
  width: 11rem;
}

.bk-login .right-bk .bk-form {
  margin-bottom: 1.5rem;
}

.bk-jarallax {
  height: 20rem;
}

@media (min-width: 992px) {
  .bk-jarallax {
    height: 28rem;
  }
}

@media (min-width: 1681px) {
  .bk-jarallax {
    height: 37.5rem;
  }
}

.bk-relative {
  position: relative;
}

.js-owl-nav {
  max-width: 5.2rem;
  margin: 0 auto;
}

.js-owl-nav button {
  border: none;
  background-color: transparent;
  position: relative;
  z-index: 5;
  height: 2rem;
  width: 2rem;
  border: 1px solid #2d825b;
  transition: .4s;
}

@media (min-width: 768px) {
  .js-owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.js-owl-nav button:focus {
  outline: none !important;
}

@media (min-width: 992px) {
  .js-owl-nav button {
    height: 3rem;
    width: 3rem;
  }
}

@media (min-width: 768px) {
  .js-owl-nav button.owl-prev {
    left: -2rem;
  }
}

@media (min-width: 992px) {
  .js-owl-nav button.owl-prev {
    left: -3rem;
  }
}

@media (min-width: 768px) {
  .js-owl-nav button.owl-next {
    right: -2rem;
  }
}

@media (min-width: 992px) {
  .js-owl-nav button.owl-next {
    right: -3rem;
  }
}

.js-owl-nav button:hover {
  background-color: #2d825b;
}

.js-owl-nav button:hover svg {
  fill: #fff;
}

.js-owl-nav button svg {
  height: 50%;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #2d825b;
  transition: .4s;
}

.bk-benefit-pg:nth-child(even) {
  background-color: #f5f6f7;
}

.bk-benefit-pg:nth-child(even) .col-lg-3 {
  margin-left: auto;
}

.bk-benefit-pg:nth-child(odd) .row {
  flex-direction: row-reverse;
}

.bk-benefit-pg:nth-child(odd) .row .col-lg-3 {
  margin-right: auto;
}

.bk-benefit-pg .title-bk {
  max-width: 40rem;
}

.bk-benefit-pg .h3, .bk-benefit-pg h3 {
  margin-bottom: 1.5rem;
}

.bk-benefit-pg p {
  color: #2d825b;
}

.bk-benefit-pg .btn {
  margin: inherit;
  margin-top: 2rem;
}

@media (min-width: 1200px) {
  .bk-benefit-pg .btn {
    margin-top: 3rem;
  }
}

.bk-benefit-pg ul {
  display: flex;
  align-items: flex-start;
  padding-left: 0;
  margin-top: 3rem;
}

.bk-benefit-pg li {
  list-style: none;
  margin-left: 2rem;
}

.bk-benefit-pg li:first-child {
  margin-left: 0;
}

.bk-benefit-pg figure {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .bk-benefit-pg figure {
    margin-top: 2rem;
  }
}

.bk-benefit-pg figure img {
  max-width: 13rem;
}

@media (min-width: 768px) {
  .bk-benefit-pg figure img {
    max-width: 100%;
  }
}
