.bk-mob-tog{
  @extend %ResetButton;
  color: inherit;
  position: relative;
  height: 3rem;
  margin-left: .8rem;
  padding: 0 0 0 2rem;
  outline: 0 !important;
  color: $primary-color;

  @include mq(sm){
    margin-left: 1.5rem;
    padding-left: 2rem;
  }

  &.active{
    .bk-burger-menu{
      &::before{
        transform: translate(-50%, -50%) translateY(0) rotate(-45deg);
      }
      &::after{
        transform: translate(-50%, -50%) translateY(0) rotate(45deg);
      }
    }
  }

  .bk-burger-menu{
    width: 2.2rem;
    height: .4rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    &::before,
    &::after{
      width: .8rem;
      height: 1px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $primary-color;
      transition: transform .4s;
      @include mq(sm){
        width: 1.2rem;
        height: 2px;
      }
    }
    &::before{
      transform: translate(-50%, -50%) translateY(-2px) rotate(0);
      @include mq(sm){
        transform: translate(-50%, -50%) translateY(-3px) rotate(0);
      }
    }
    &::after{
      transform: translate(-50%, -50%) translateY(2px) rotate(0);
      @include mq(sm){
        transform: translate(-50%, -50%) translateY(3px) rotate(0);
      }
    }
  }
  span{
    display: block;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 600;
    @include mq(sm){
      font-size: .9rem;
      margin-left: 1.5rem;
    }
  }
}
.bk-mob-nav-mask{
  position: fixed;
  right: 0;
  top: 0;
  z-index: 320;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  transition: .4s;

  &.active{
    transform: translateX(0);
  }
}
.bk-mob-nav{
  background-color: $primary-color;
  color: $primary-font-color;
  font-family: $secondary-family;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -280px;
  z-index: 321;
  transition: 0.4s;
  z-index: 999999;

  &.active {
    right: 0;
  }

  .contain-bk {
    width: 100%;
    height: 100%;
    padding: 1.2rem 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  a{
    display: inline-block;
		color: $white;

    &::after{
      background-color: $white;
    }

    &:hover{
      color: $white;
    }
	}

  .current_page_item{
    a{
      color: $secondary-color;
    }
  }

  .grid-bk{
    text-align: center;

    ul{
  		margin: 0;
  		padding: 0;
  		list-style: none;

  		li{
        width: 50%;
        position: relative;
  			margin: 0;
  			padding: 0;

        a{
          display: block;
          background-color: $black;
          text-transform: uppercase;
          padding: 1rem .5rem;
          font-weight: 600;
          letter-spacing: 1px;

          &.active{
            background-color: transparent;
          }
        }
      }
    }
  }

  .list-bk{
    ul{
  		margin: 0;
  		padding: 0;
  		list-style: none;

  		li{
        position: relative;
  			margin: 0;
  			padding: .5rem 0;

        &:first-child{
          border-top: none;
        }

        a{
          position: relative;
          margin-left: 1.5rem;
          margin-right: 3rem;
          padding: .25rem 0;
          font-family: $secondary-family;
          font-size: .9rem;
          font-weight: 500;
          text-transform: uppercase;
        }

        ul{
          display: none;
          background-color: rgba(255,255,255,0.1);
          color: $primary-font-color;

          li{
            border-top: none;

            button{
              background-color: $primary-color;
              color: $primary-font-color;
            }

            ul{
              margin-left: 1rem;
              background-color: $primary-color;
              color: $primary-font-color;

              ul{
                background-color: rgba(255,255,255,0.1);
              }
            }

            a{
              position: relative;
              text-transform: none;
            }
          }
        }
  		}
  	}
    .active{
      > button{
        &::before{
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    button{
      @extend %ResetButton;
      cursor: pointer;
      display: block;
      width: 3rem;
      height: 100%;
      max-height: 3.4rem;
      position: absolute;
      top: 0;
      right: 0;
      margin: auto;
      z-index: 4;
      background-color: transparent;
      color: $white;
      transition: .4s;

      &::before{
        @extend %Fa;
        content: url("../images/white-down-arrow.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0);
        transition: .4s;
      }
    }
  }

  address{
    margin: auto 0 0 0;
    padding: 2rem 0 3rem;
    font-style: normal;

    p{
      border-top: solid 1px $border;
      font-weight: 300;
      padding: 1.5rem 0 0;
    }
  }

  .social-bk{
    display: block;
    text-align: center;
    border-top: solid 1px $border;
    padding: 1.5rem 0 0 0;
    margin: 1.5rem 0 0;

    a{
      color: $white;
      height: 20px;
      margin: 0 .5rem;
      font-size: 1.2rem;

      &:hover{
        color: $white;
      }
    }
  }

  .contact-bk{
    padding: 2rem 1.5rem;
    display: flex;
    align-items: center;

    a{
      margin-left: 1.5rem;

      &:first-child{
        margin-left: 0;
      }
    }
  }
}

.bk-get-quote{
  position: fixed;
  bottom: 1rem;
  right: 1.2rem;
  z-index: 99;

  .btn{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    padding: .3rem;
    justify-content: center;
    box-shadow: $box-shadow;
    min-width: auto;

    svg{
      margin: 0;
    }
  }
}
