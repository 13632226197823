.bk-list{
  ul{
    padding-left: 0;
  }
  li{
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.8rem;
    list-style: none;

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 9px;
      height: 12px;
      background-color: $secondary-color;
    }
  }
}
