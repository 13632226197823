body {
  &.admin-bar {
    .bk-header {
      top: 32px;
    }
  }
}
@media screen and (max-width: 782px) {
  body {
    &.admin-bar { 
      .bk-header {
        top: 46px;
      }
    }
  }
}

.bk-header {
  width: 100%;
  background-color: $white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: $button-font-weight;
  line-height: 1.2;
  transition: 0.4s;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  @include mq(xl){
    font-size: 1.1rem;
   }
   @include mq(w3){
    font-size: 1.2rem;
   }
  @include mq(mxl) {
    padding: 0.8rem 0;
  }

  &.fixed {
    .header-bk {
      position: fixed;
    }
  }

  &.active {
    left: -280px;
    @include mq(xl) {
      left: 0;
    }
  }

  a {
    transition: 0.4s;
    text-transform: capitalize;

    &:not(.btn):not(.obj):not(.button) {
      &::after {
        display: none;
      }
    }
  }

  nav {
    a {
      color: $link-color;
      font-weight: 700;

      &:hover {
        color: $secondary-color;
      }
    }

    .current_page_item{
      a{
        color: $secondary-color;
      }
    }

    & > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      & > li {
        margin: 0;
        padding: 2.5rem 1.8rem;
        position: relative;
        display: flex;
        align-items: center;
        @include mq(mw6) {
          padding: 2rem 1.2rem;
        }

        &.menu-item-has-children {
          & > a {
            &:after {
              content: url("../images/down-arrow.svg");
              display: inline-block !important;
              width: 0.9rem;
              vertical-align: 2px;
              margin-left: 5px;
            }
          }

          &:hover {
            & > a {
              color: $primary-color;

              &:after {
                content: url("../images/green-down-arrow.svg");
              }
            }

            ul.sub-menu {
              display: block;
            }
          }
        }
      }
    }
  }

  .logo-bk {
    padding-right: 1.8rem;
    @include mq(mw6) {
      padding-right: 1rem;
    }

    a {
      display: block;
    }

    img {
      width: auto;
       height: 3rem;
      @include mq(md){
        height: 3.3rem;
      }
    }
  }

  .right-bk {
    @include mq(mxl) {
      margin-left: auto;
    }

    ul {
      margin-bottom: 0;
      padding-left: 0;

      li {
        padding-left: 1.8rem;
        @include mq(msm) {
          padding-left: 1.2rem;
        }
      }
    }

    .btn {
      &:hover {
        color: $primary-color;
      }
    }
  }

  &.fixed-top {
    position: fixed;
    width: 100%;
    background-color: #fff;
    -webkit-animation: 0.7s fade-in;
    animation: 0.7s fade-in;
    z-index: 999;
    animation: 0.7s ease-in-out 0.1s normal both 1 running stickyhead;
    box-shadow: $box-shadow-hover;
  }
}
@keyframes stickyhead {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.bk-header {
  li.menu-item-has-children {
    ul.sub-menu {
      display: none;
      white-space: nowrap;
      min-width: 12rem;
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      font-weight: 400;
      background-color: $white;
      box-shadow: 0 0.15rem 0.25rem rgba(0,0,0,0.1);
      position: absolute;
      top: 100%;
      left: 0;
      margin: 0;
      transform: translateY(-1px);

      li {
        width: 100%;
        margin: 0;
        padding: 0.25rem 0;
        position: relative;
        transition: 0.4s;

        &:hover {
          background-color: $primary-color;

          & > ul {
            display: block;
          }

          & > a {
            color: $white;
          }
        }

        a {
          display: block;
          padding: 0.5rem 1rem;
          text-transform: none;

          &::after {
            display: none;
          }
        }

        ul {
          display: none;
          min-width: 12rem;
          margin: 0;
          padding: 0;
          list-style: none;
          position: absolute;
          top: 0;
          left: 100%;
          transform: translateX(-1px);
          background-color: $white;
          box-shadow: 0 0.15rem 0.25rem rgba(0,0,0,0.1);
        }
      }
    }
  }

  aside{
    .show-sm{
      display: none;
      @include mq(sm){
        display: flex;
      }
    }
  }
}

.bk-inner-header{
    border-bottom: 1px solid #e7e7e7;
}
