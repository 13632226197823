.bk-offer{
  background-color: rgba(126, 155, 60, .21);
  padding: 3rem 2rem;
  height: 100%;
  color: $primary-color;
  font-family: $secondary-family;
  font-weight: 400;

  &:before{
    content: "";
    background: url("../images/logo-icon.svg") no-repeat center / contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 50%;
    opacity: .1;
  }

  .title-bk{
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-family: $secondary-family;
    color: $primary-color;
    background-color: $white;
    padding: .5rem .8rem;
    @include mq(md){
      padding: .8rem 1rem;
    }
    @include mq(w6){
      font-size: 1.7rem;
    }
  }

  .offer-bk{
    font-size: 1.1rem;
    @include mq(sm){
      font-size: 1.3rem;
    }
  }

  .price-bk{
    font-size: 1.1rem;
    font-weight: 700;

    @include mq(sm){
      font-size: 1.3rem;
    }
  }

  .bk-benefit{
    margin: 2rem 0px;
    font-size: 1.2rem;
    text-transform: uppercase;

    li{
      margin-bottom: .3rem;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  ul{
    margin: 0;
    padding: 0;
  }

  li{
    list-style: none;
  }

  .bk-list{
    li{
      padding-left: 2.2rem;
      &:last-child{
        margin-bottom: 0;
      }

      &:before{
        background: url("../images/logo-icon.svg") no-repeat center / cover;
        width: 17px;
        height: 23px;
        top: 1px;
      }
    }
  }
}
