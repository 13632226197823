#wpforms-122,
.form-group {
  font-family: $secondary-family;
  color: $primary-color;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;

  label {
    display: block;
    margin-bottom: .75rem;
    font-weight: inherit;
    font-size: inherit;
  }

  .form-control,
  input,
  textarea {
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    border: 1px solid $primary-color;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    background-color: transparent;
    color: $primary-color;

    &::placeholder {
      color: $primary-color;
    }
  }

  textarea,
  textarea.form-control {
    height: 10rem;
    padding: 1rem;
    resize: none;
  }

  .btn {
    margin: inherit;
  }

  .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 8rem;
    margin: auto;
    padding: 0.45rem 1.5rem 0.3rem;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    z-index: 0;
    text-align: center;
    background-color: #2d825b;
    color: #ffffff;
    border-color: #2d825b;
    border-radius: 0;
    border: solid 1px transparent;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    -webkit-transition: 0.4s all ease;
    transition: 0.4s all ease;

    &:hover {
      box-shadow: none;
      background-color: #ffffff;
      color: #2d825b;
      border-color: #2d825b;
    }
  }
}
.form-group{
  margin-bottom: 1rem;
}
