$primary-color: #2d825b !default;
$primary-font-color: #646464 !default;
$secondary-color: #99cc33 !default;
$secondary-font-color: #ffffff !default;
$link-color: #006838 !default;

$accent-color: #f06c2f;
$light-color: #e6c9bc;
$highlight-color: #d67649;
$background-color: #ffffff;

$border-radius: 1.2rem !default;
$border-color: #d9d9d9 !default;
$border-color: #d9d9d9 !default;

$text: #646464;
$text-body: #565656 !default;
$body-light-text: #737373 !default;
$light-text: #959595 !default;
$light-text-on-dark: #c0c0c0 !default;
$headings-text: inherit !default;

$white: #ffffff !default;
$black: #000000 !default;
$dark: #111111 !default;
$gray: #f6f6f6 !default;
$darkgray: #cccccc !default;
$brown: #ac877c !default;
$border: #ebebeb !default;
$blue: #007bff !default;
$yellow: #fff102 !default;
$green: #28a745 !default;
$red: #f44336 !default;
$grey: #f5f6f7 !default;

$warning: #ffc107 !default;
$danger: #f44336 !default;
$success: #4caf50 !default;


$font-size: 1.1rem !default;
$font-heading-size: 2.8rem !default;
$font-weight: 400 !default;
$line-height: 1.6 !default;

$button-font-size: 1rem !default;
$button-font-weight: 400 !default;
$infinite: 99999999px;

$box-shadow: 0 .25rem .5rem rgba(0,0,0,0);
$box-shadow-hover: 0 .25rem 1rem rgba(0,0,0,0.2);

$primary-family: -apple-system, BlinkMacSystemFont,'Roboto', sans-serif !default;
$secondary-family: -apple-system, BlinkMacSystemFont,'Rubik', sans-serif !default;
