.h1{
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
    @include mq(md){
      font-size: 2.3rem;
    }
  @include mq(md){
    font-size: 2.5rem;
  }
  @include mq(xl){
    font-size: 2.5rem;
  }
  @include mq(w3){
    font-size: 2.8rem;
  }
}
h1{
  @extend .h1;
}
.h1,.h2,.h3{
  font-family: $secondary-family;
  color: $primary-color;
  margin-bottom: 1rem; 
  font-weight: 800;
}

.h2{
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  @include mq(md){
    font-size: 2rem;
  }
   @include mq(xl){
    font-size: 2.5rem;
  }
  @include mq(w3){
    font-size: 2.6rem;
  }
}

h2{
  @extend .h2;
}

.h3{
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2rem;
  color: $primary-color;
  @include mq(xl){
    font-size: 1.5rem;
  }
}

h3{
  @extend .h3;
}
