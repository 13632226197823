.d-flex-parent > *{
  display: flex;
}
.flex-wrap-parent > *{
  flex-wrap: wrap;
}

.bk-btn{
  margin-left: -.5rem;
  .btn{
    margin-left: .5rem;
    margin-bottom: .5rem;
  }
}
.bk-page-nav{
  background-color: $white;
  text-align: center;

  a{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

::selection{
  color: $white;
  background-color: $primary-color;
}

@mixin cmmn_spacing(){
    padding: 2.5rem 0rem;

    @include mq(sm){
      padding: 3rem 0rem;
    }

    @include mq(lg){
      padding: 3.8rem 0rem;
    }

    @include mq(xl){
       padding: 5rem 0rem;
   }

    @include mq(w6){
        padding: 6.5rem 0rem;
    }

}
.spacing{
  @include cmmn_spacing;
}

@mixin cmmn_spacing_top(){
    padding-top: 2.5rem;

    @include mq(sm){
      padding-top: 3rem;
    }

    @include mq(lg){
      padding-top: 3.8rem;
    }

    @include mq(xl){
       padding-top: 5rem;
   }

    @include mq(w6){
        padding-top: 6.5rem;
    }
}
.spacing-top{
  @include cmmn_spacing_top;
}

@mixin cmmn_spacing_bottom(){
    padding-bottom: 2.5rem;

    @include mq(sm){
      padding-bottom: 3rem;
    }

    @include mq(lg){
      padding-bottom: 3.8rem;
    }

    @include mq(xl){
       padding-bottom: 5rem;
   }

    @include mq(w6){
        padding-bottom: 6.5rem;
    }
}

.spacing-bottom{
  @include cmmn_spacing_bottom;
}
