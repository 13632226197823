.bk-banner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  color: $secondary-font-color;
  padding: 2.5rem 0 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include mq(sm) {
    padding: 3rem 0 0;
  }
  @include mq(md) {
    padding: 4rem 0 0;
  }
  @include mq(mxl) {
    min-height: 100% !important;
  }


  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);

    @include mq(lg){
      display: none;
    }
  }
  .video-bk{
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    video{
      position: absolute;
      top: 0;
      left: 0;
      object-fit:cover;
      height: 100%;
      width: 100%;
    }
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #163c2a;
      opacity: .7;
    }
  }
  .playpause-holder{
    height: 3rem;
    position: relative;
    margin-top: 2rem;
    @include mq(lg){
      display: none;
    }
  }
  .playpause{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
      svg{
         height: 3rem;
      width: 3rem;
      fill: #fff;
      }
  }

  h1 {
    color: $secondary-font-color;
    font-weight: 800;
     font-size: 2.3rem;
  line-height: 1;
  text-transform: uppercase;
  @include mq(md){
    font-size: 2.5rem;
  }
   @include mq(lg){
    font-size: 2rem;
  }
  @include mq(xl){
    font-size: 2.5rem;
  }
  @include mq(w3){
    font-size: 2.8rem;
  }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .3rem;
    background-color: $white;
    z-index: 1;
    @include mq(sm) {
      height: .5rem;
      display: block;
    }
    @include mq(lg) {
      height: 1.2rem;
    }
  }

  .container-fluid {
    position: relative;
    z-index: 2;
    @include mq(mlg) {
      display: flex;
      flex-direction: column;
    }
  }

  article {
    max-width: 32rem;
    text-align: center;
    margin: 0 auto 2rem;
    @include mq(md) {
      margin: 0 auto 2.8rem;
    }
    @include mq(lg) {
      position: absolute;
      left: 50%;
      bottom: 19%;
      transform: translateX(-50%);
      margin: inherit;
      max-width: 30rem;
    }
    @include mq(xl) {
      left: 50%;
      bottom: 18%;
        max-width: 34rem;
    }
    @include mq(w3){
         max-width: 38rem;
      }

    @include mq(mlg) {
      order: 1;
    }
  }

  figure {
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    position: relative;
    margin-bottom: 0;
    width: 90%;
    max-width: 400px;
    @include mq(lg) {
     transform: translateX(-42%);
     width: 90%;
     max-width: 90%;
    }
    @include mq(xl){
      left: 50%;
      width: 100%;
      width: max-content;
    }
    @include mq(mlg) {
      order: 2;
    }

    img{
      @include mq(xl){
        height: 34rem;
      }
      @include mq(w3){
        height: 37rem;
      }
    }
  }


  p {
    font-size: 1.2rem;
    line-height: 1;
     @include mq(w3){
       font-size: 1.4rem;
      }
  }

  .btn {
    font-size: 1.2rem;
    border: none;
    padding: .6rem 1.5rem;
  }

  .js-scroll-bk {
    animation: bounce 2s infinite;
    cursor: pointer;
  }
}
@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bk-management {
  .bk-list {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      width: 100%;
      @include mq(xl) {
        width: 50%;
      }
    }
  }
}

.bk-book {
  max-width: max-content;
  margin: 0 auto;
  position: relative;

  figure{
    margin-bottom: 0;
  }

  .details-bk {
    position: absolute;
    left: 2.2rem;
    top: 7rem;
    width: 76%;
    @include mq(x4){
      left: 2.8rem;
      top: 9rem;
    }
  }

  .title-bk {
    font-size: 1.3rem;
    font-weight: 400;
    color: $primary-color;
    text-transform: capitalize;
    margin-bottom: 0;
    @include mq(x4){
      font-size: 1.5rem;
    }
  }

  address {
    color: #113561;
    font-size: .8rem;
    font-weight: 400;
    font-family: $secondary-family;
    @include mq(x34){
      font-size: 1rem;
    }
  }

  .input-bk {
    margin-bottom: 0.6rem;
    color: $primary-color;

    label {
      width: 100%;
      margin-bottom: 0;
      background-color: $grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;

      span {
        &:first-child {
          width: 50%;
        }

        &:nth-child(2) {
          width: calc(40% - 9px);
        }

        &:nth-child(3) {
          width: 9px;
        }
      }
    }

    input[type=checkbox] {
      opacity: 0;
      height: 0;
      position: absolute;
    }

    input[type=checkbox]:checked ~ label {
      background-color: $primary-color;
      color: $secondary-font-color;

      path {
        fill: $secondary-font-color;
      }
    }
  }

  .buttons-bk {
    display: flex;
    align-items: center;
    margin: 1.8rem 0;

    button:not(.btn) {
      background-color: transparent;
      border: none;
      outline: 0;
      padding: 0;
    }

    button {
      margin-right: 1rem;
      margin-left: 0;
    }
  }

  .amounts-bk {
    display: flex;
    align-items: flex-start;
    margin: 0 -7px;

    input{
      width: 100%;
      height: 2.5rem;
      outline: 0;
      border-radius: 0;
      border: 1px solid $primary-color;
      color: $primary-color;
      font-size: 1rem;
      padding: 0 .8rem;
    }

    input::placeholder{
      color: $primary-color;
    }

    label{
      font-size: .9rem;
      color: $primary-color;
      line-height: .9rem;
      margin-bottom: .5rem;
    }
  }

  .inner-bk{
    width: 50%;
    padding: 7px;
  }

  .btn {
    font-size: 0.9rem;
    @include mq(mx4){
      padding: .3rem 1rem;
    }

    &:before {
      width: 1.3rem;
      height: 1.8rem;
    }
  }
}

.bk-speciality {
  background: url("../images/speciality-bg.jpg") no-repeat center / cover;
  position: relative;
  padding: 3rem 0;
  @include mq(lg) {
    padding: 3rem 0 4rem;
  }
  @include mq(xl) {
    padding: 4rem 0;
  }
  @include mq(w6) {
    padding: 6rem 0 7rem;
  }
  @include mq(mmd) {
    color: $secondary-font-color;
  }

  h2 {
    @include mq(mmd) {
      color: $secondary-font-color;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    width: 122%;
    background: url("../images/shape1.svg") no-repeat center / cover;
    height: 26rem;
    display: none;
    @include mq(md) {
      display: block;
    }
    @include mq(lg) {
      height: 26rem;
      width: 103%;
    }
    @include mq(xl) {
      height: 33rem;
      width: 106%;
    }
    @include mq(w6) {
      height: 39rem;
      width: 100%;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.bk-services {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 1rem;
  @include mq(md) {
    padding-top: 13rem;
  }
  @include mq(lg) {
    padding-top: 17rem;
  }
  @include mq(xl) {
    padding-top: 20rem;
  }

  .item-bk {
    width: 100%;
    // background-color: rgba(255,255,255,.30);
    background-color: $white;
    padding: 2rem;
    color: $primary-color;
    margin-bottom: 3px;
    transition: all 0.2s ease;
    text-align: center;
    @include mq(sm) {
      width: calc(100% / 2 - 2px);
    }
    @include mq(sm) {
      text-align: left;
    }
    @include mq(xl) {
      width: calc(100% / 3 - 2px);
    }
    path {
        fill: $primary-color;
      }

    &:hover {
      background-color: $primary-color;
      color: $white;

      path {
        fill: $white;
      }
    }
  }

  figure {
    height: 3.5rem;
    text-align: center;
    margin-bottom: 1rem;
    @include mq(md) {
      text-align: right;
      margin-bottom: 1.5rem;
    }
  }

  p {
    max-width: 20rem;
    margin: auto;
    font-weight: 400;
    @include mq(md) {
      margin: 0;
    }
  }
}

.bk-testimonial {
  @include mq(mmd) {
    text-align: center;
  }

  .left-bk {
    overflow: hidden;
    border-radius: 1.5rem;
    padding-bottom: 60%;

    img{
      object-position: 81% 50%;
    }
  }

  h1,
  p {
    margin-bottom: 1.5rem;
  }

  .author-bk {
    font-size: 1.2rem;
    color: $primary-color;
    font-weight: 500;
  }
}

.bk-choice{
  height: 30rem;
  position: relative;
  &:after{
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
        height: 100%;
    width: 100%;
    // background-image: linear-gradient(white, #ffffff00);
    z-index: -1;
    // @include mq(sm){
    //   display: none;
    // }
    opacity: .5;
        background-color: #163c2a;
    opacity: .7;
  }
  @include mq(md){
    height: 0rem;
    padding-bottom: 35%;
  }

  .jarallax-img{
    object-position: 78% 50% !important;
    @include mq(xl){
      object-position: 50% 50% !important;
    }
  }

  .title-bk{
    // font-weight: 400;
    // font-size: 1.8rem;
    // line-height: 2rem;
    // text-transform: inherit;
    // margin-bottom: 1rem; 
    // @include mq(md){
    //   font-size: 2.4rem;
    //   line-height: 2.5rem;
    //   margin-bottom: 1.5rem;
    // }
  //  @include mq(xl){
  //   font-size: 2.5rem;
  // }
  // @include mq(w3){
  //   font-size: 2.6rem;
  // }
  color: $white;
  }

  p{
    // color: $primary-color;
    color: $white;
    margin-bottom: 1rem;
       font-size: 1.2rem;
    @include mq(md){
      margin-bottom: 1.5rem;
    }
     @include mq(w3){
       font-size: 1.4rem;
      }
  }

  .btn{
    margin: inherit;
  }
}


.bk-line {
    position: absolute;
    top: 38%;
    z-index: -1;
    @include mq(xl){
      top: 26%;
    }
    @include mq(w6){
      top: 25%;
    }

    @include mq(mmd){
      display: none;
    }
}

.bk-contact{
  .title-bk{
    font-family: $secondary-family;
    color: $primary-color;
    font-weight: 400;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-size: 1.2rem;
    @include mq(xl){
      font-size: 1.3rem;
    }
  }

  p{
    color: #8a94a6;
  }
}

.bk-login{
  .right-bk{

    @include mq(sm){
      padding-left: 2.5rem;
    }

    @include mq(md){
      border-left: 1px solid $primary-color;
      padding-left: 5rem;
      padding-bottom: 1.5rem;
    }

    .title-bk{
      font-size: 1rem;
      font-weight: 400;
      font-family: $secondary-family;
      color: $primary-color;
      margin-bottom: 1.5rem;
      text-transform: capitalize;
    }

    .btn2{
      margin: inherit;
      padding: 1rem 1rem;
      font-weight: 400;
    }

    .btn,.btn2{
      width: 11rem;
    }

    .bk-form{
      margin-bottom: 1.5rem;
    }
  }
}

.bk-jarallax{
  height: 20rem;
  @include mq(lg){
    height: 28rem;
  }
  @include mq(w6){
    height: 37.5rem;
  }
}
.bk-relative{
  position: relative;
}
.js-owl-nav{
    max-width: 5.2rem;
    margin: 0 auto;
  button{
    border: none;
    background-color: transparent;
   position: relative;
    z-index: 5;
    height: 2rem;
    width: 2rem;
    border: 1px solid #2d825b;
    transition:.4s;
     @include mq(md){
       position: absolute;
    top: 50%;
    transform: translateY(-50%);
     }
     &:focus{
      outline: none!important;
     }
     @include mq(lg){
      height: 3rem;
    width: 3rem;
    }
    &.owl-prev{
       @include mq(md){
      left: -2rem;
    }
     @include mq(lg){
      left: -3rem;
    }
    }
    &.owl-next{
       @include mq(md){
      right: -2rem;
    }
     @include mq(lg){
      right: -3rem;
    }
    }
    &:hover{
      background-color: #2d825b;
      svg{
        fill: #fff;
      }
    }
    svg{
      height: 50%;
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #2d825b;
      transition:.4s;
    }
  }
}
.bk-benefit-pg{
  &:nth-child(even){
    background-color: $grey;

    .col-lg-3{
        margin-left: auto;
    }
  }

  &:nth-child(odd) .row{
    flex-direction: row-reverse;

    .col-lg-3{
        margin-right: auto;
    }
  }

  .title-bk{
    max-width: 40rem;
  }

  .h3{
    margin-bottom: 1.5rem;
  }

  p{
    color: $primary-color;
  }

  .btn{
    margin: inherit;
    margin-top: 2rem;
    @include mq(xl){
      margin-top: 3rem;
    }
  }

  ul{
    display: flex;
    align-items: flex-start;
    padding-left: 0;
    margin-top: 3rem;
  }

  li{
    list-style: none;
    margin-left: 2rem;

    &:first-child{
      margin-left: 0;
    }
  }

  figure{
    margin-bottom: 0;
     @include mq(mmd){
       margin-top: 2rem;
     }

     img{
       max-width: 13rem;
       @include mq(md){
         max-width: 100%;
       }
     }
  }
}
