@keyframes flare {
  0% {
    width: 0;
    left: -10%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 0;
    left: 110%;
  }
}

.btn {
  @extend %Button;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 8rem;
  margin: auto;
  padding: .4rem 1.5rem;
  text-transform: capitalize;
   border: solid 1px $primary-color;

  &:before{
    content: "";
    background: url("../images/logo-icon.svg") no-repeat center / contain;
    width: 2rem;
    height: 2.2rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: .6rem;
  }

  path{
    transition: .4s all ease;
  }

  &:hover{
    path{
      fill: $primary-color;
    }
  }
}

.btn2 {
  @extend %Button;
  background-color: $white;
  color: $link-color;
  border-color: $link-color;
  // font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 8rem;
  margin: auto;
  padding: .97rem 2.5rem;
  outline: 0;
  border-radius: 0;

  &:hover{
    background-color: $primary-color;
    color: $white;
  }

  @include mq(md){
    padding: .97rem 3.5rem;
  }
}

.view-more {
  padding: 0.8rem 2.5rem;
}

a {
  transition: all 0.2s ease;
}

.btn-border {
  background-color: transparent;
  color: $primary-color;
  border-color: $primary-color;

  &:active,
  &:focus {
    background-color: transparent;
    color: $primary-color;
    border-color: $primary-color;
  }

  &:hover {
    background-color: transparent;
    color: $primary-color;
    border-color: $primary-color;
  }
}

.btn-light {
  background-color: $white;
  color: $primary-color;
  border-color: $white;

  &:active,
  &:focus {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  &:hover {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}

.btn-light.btn-border {
  background-color: transparent;
  color: $white;
  border-color: $white;

  &:active,
  &:focus {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  &:hover {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}
// .btn-secondary{
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;
//   text-transform: uppercase;
//   @include mq(sm){
//     padding-left: 2rem;
//     padding-right: 2rem;
//   }
//   @include mq(md){
//     padding-left: 2.5rem;
//     padding-right: 2.5rem;
//   }
//   @include mq(xl){
//     padding-left: 3rem;
//     padding-right: 3rem;
//   }
//
//   &:hover{
//     &::before{
//       transform: skewX(-25deg);
//       animation: flare 2s linear infinite;
//     }
//   }
//
//   &::after{
//     display: none;
//   }
//
//   &::before{
//     display: block;
//     width: 0%;
//     height: 100%;
//     content: '';
//     position: absolute;
//     top: 0;
//     left: -10%;
//     z-index: -1;
//     background: -webkit-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,.1) 80%,rgba(255,255,255,0) 100%);
//     background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.1) 80%,rgba(255,255,255,0) 100%);
//     transform: skewX(-25deg);
//     transition: all .4s ease;
//   }
// }
// .btn-light.btn-secondary{
//   &::before{
//     background: -webkit-linear-gradient(left,rgba(45,70,57,0) 0%,rgba(45,70,57,.1) 80%,rgba(45,70,57,0) 100%);
//     background: linear-gradient(to right,rgba(45,70,57,0) 0%,rgba(45,70,57,.1) 80%,rgba(45,70,57,0) 100%);
//   }
// }

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $primary-font-color;
}
@keyframes right {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.btn-link {
  font-family: $secondary-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  color: $primary-color;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  padding: 0.5rem 3rem 0.5rem 0;

  &:hover {
    color: $primary-color;
    text-decoration: none;

    &::after {
      animation: right 2s infinite;
    }
  }

  &::after {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    margin-left: 0.5rem;
    content: '';
    vertical-align: middle;
    background-image: url("../images/icon-arrow-right.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}
